import React, { /*PureComponent*/ memo, useState, useEffect, useRef } from 'react';
import Ajax from './Ajax';
import { Input, Button, AutoComplete } from './base';
import { CheckIsNullOrUndefined, trimWhiteSpace } from './Common';
import { ClockCircleOutlined, SearchOutlined } from '@ant-design/icons';

const { Group: InputGroup } = Input;
const { Option } = AutoComplete;

const getSmartSearchHistoryUrl = `${process.env.PUBLIC_URL}/api/v1/AutoSave/GetSmartSearchHistory`;
const upsertSmartSearchHistoryUrl = `${process.env.PUBLIC_URL}/api/v1/AutoSave/UpsertSmartSearchHistory`;

//class SearchBoxWithAutoComplete extends PureComponent {

//    constructor(props) {
//        super(props);
//        this.state = {
//            data: [],
//            searchText: ''
//        }
//    }

//    componentDidMount() {
//        this.getSmartSearchHistory();
//    }

//    inputBoxStyle = {
//        //width: `${100 - searchButtonPercent}%`
//        flex: 1
//    }

//    searchButtonStyle = {
//        //width: `${searchButtonPercent}%`,
//        flex: `0 1 ${this.props.searchButtonPercent}%`,
//        minWidth: `${this.props.searchButtonMinWidth}px`
//    }

//    inputProps = {
//        style: this.inputBoxStyle,
//        placeholder: this.props.placeholder,
//        name: this.props.name,
//        suffix: this.props.suffix,
//        //onPressEnter: this.props.onPressEnter,
//        allowClear: this.props.allowClear
//    }

//    getSmartSearchHistory = async () => {
//        let data = [];
//        try {
//            let res = await Ajax.get(getSmartSearchHistoryUrl);

//            if (res && res.data) {
//                data = res.data;
//            }
//        }
//        catch (error) {

//        }

//        this.setState({ data });
//    }

//    handleClick = async () => {
//        const { searchText } = this.state;
//        const { onClick } = this.props;

//        if (searchText !== '' && !CheckIsNullOrUndefined(searchText)) {
//            await Ajax.post(upsertSmartSearchHistoryUrl, {
//                id: -1,
//                search_text: trimWhiteSpace(searchText)
//            });

//            this.getSmartSearchHistory();
//        }

//        onClick && onClick();
//    }

//    handleSearch = (searchText) => {
//        const { onSearch } = this.props;
//        this.setState({ searchText: trimWhiteSpace(searchText) }, () => {
//            onSearch && onSearch();
//        });
//    }

//    handleSelect = (searchText, option) => {
//        const { onSelect } = this.props;

//        this.setState({ searchText: trimWhiteSpace(searchText) }, () => {
//            onSelect && onSelect(trimWhiteSpace(searchText));
//        });
//    }

//    render() {
//        const { value, type, buttonText, searchIconName, onClick, onChange, style, onBlur, optionClassName, disabled } = this.props;
//        const { data, searchText } = this.state;

//        if (value !== undefined && value !== null) {
//            this.inputProps.value = value;
//        }

//        if (onChange !== undefined && onChange !== null) {
//            this.inputProps.onChange = onChange;
//        }

//        let searchResult = data.filter(dat => dat.search_text.toLowerCase().includes(searchText.toLowerCase()));
//        let children = searchResult.map(res => <Option className={optionClassName} key={res.search_text} title={res.search_text} value={res.search_text}><div><ClockCircleOutlined /> {res.search_text}</div></Option>)

//        return (
//            <InputGroup compact style={{ ...style, display: 'flex' }}>
//                <AutoComplete
//                    disabled={disabled}
//                    onSelect={(searchText, option) => {
//                        this.handleSelect(trimWhiteSpace(searchText), option);
//                    }}
//                    onSearch={(text) => {
//                        this.handleSearch(text);
//                    }}
//                    onBlur={() => {
//                        onBlur && onBlur(trimWhiteSpace(searchText));
//                    }}
//                    //optionLabelProp="value"
//                    //dataSource={children}
//                    {...this.inputProps}
//                >
//                    {children}
//                </AutoComplete>
//                <Button
//                    style={this.searchButtonStyle} type={type} onClick={() => {
//                        this.handleClick();
//                        //20210505 #4064 Duplicate fire click //onClick && onClick();
//                    }}
//                    disabled={disabled}
//                >
//                    <SearchOutlined />{buttonText}
//                </Button>
//            </InputGroup>
//        );
//    }
//}

const SearchBoxWithAutoComplete = memo(function SearchBoxWithAutoComplete(props) {
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    let didMount = useRef(false);
    const { value, type, buttonText, onChange, style, onBlur, optionClassName, disabled } = props;

    const inputBoxStyle = {
        //width: `${100 - searchButtonPercent}%`
        flex: 1
    }

    const searchButtonStyle = {
        //width: `${searchButtonPercent}%`,
        flex: `0 1 ${props.searchButtonPercent}%`,
        minWidth: `${props.searchButtonMinWidth}px`
    }

    let inputProps = {
        style: inputBoxStyle,
        placeholder: props.placeholder,
        name: props.name,
        suffix: props.suffix,
        //onPressEnter: props.onPressEnter,
        allowClear: props.allowClear
    }

    const getSmartSearchHistory = async () => {
        let data = [];
        try {
            let res = await Ajax.get(getSmartSearchHistoryUrl);

            if (res && res.data) {
                data = res.data;
            }
        }
        catch (error) {

        }

        setData(data);
    }

    const handleClick = async () => {
        const { onClick } = props;

        if (searchText !== '' && !CheckIsNullOrUndefined(searchText)) {
            await Ajax.post(upsertSmartSearchHistoryUrl, {
                id: -1,
                search_text: trimWhiteSpace(searchText)
            });

            getSmartSearchHistory();
        }

        onClick && onClick();
    }

    const handleSearch = (searchText) => {
        setSearchText(trimWhiteSpace(searchText));              
    }

    useEffect(() => {
        const { onSearch } = props;
        onSearch && onSearch();
    }, [searchText])

    useEffect(() => {
        if (!didMount.current) {
            getSmartSearchHistory();
            didMount.current = true;
        }
    }, [data])

    const handleSelect = (searchText, option) => {
        setSearchText(trimWhiteSpace(searchText));
    }

    if (value !== undefined && value !== null) {
        inputProps.value = value;
    }

    if (onChange !== undefined && onChange !== null) {
        inputProps.onChange = onChange;
    }

    let searchResult = data.filter(dat => dat.search_text.toLowerCase().includes(searchText.toLowerCase()));
    let children = searchResult.map(res => <Option className={optionClassName} key={res.search_text} title={res.search_text} value={res.search_text}><div><ClockCircleOutlined /> {res.search_text}</div></Option>)

    return (
        <InputGroup compact style={{ ...style, display: 'flex' }}>
            <AutoComplete
                disabled={disabled}
                onSelect={(searchText, option) => {
                    handleSelect(trimWhiteSpace(searchText), option);
                }}
                onSearch={(text) => {
                    handleSearch(text);
                }}
                onBlur={() => {
                    onBlur && onBlur(trimWhiteSpace(searchText));
                }}
                //optionLabelProp="value"
                //dataSource={children}
                {...inputProps}
            >
                {children}
            </AutoComplete>
            <Button
                style={searchButtonStyle}
                type={type}
                onClick={() => {
                    handleClick();
                    //20210505 #4064 Duplicate fire click //onClick && onClick();
                }}
                disabled={disabled}
            >
                <SearchOutlined />{buttonText}
            </Button>
        </InputGroup>
    );

});

SearchBoxWithAutoComplete.defaultProps = {
    buttonText: 'Search',
    placeholder: 'Search',
    searchButtonPercent: 30,
    searchButtonMinWidth: 95,
    type: 'primary',
    searchIconName: 'search',
    style: {}
};

export default SearchBoxWithAutoComplete;

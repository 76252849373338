import React, { Component } from 'react';

import { Button, Card } from '../../components/base/index';
import { getText } from '../../i18n/i18next';
import withTracker from '../../components/withTracker';
import { SetTitle } from '../../components/Common';
import { ReactComponent as CompanyLogo } from '../../img/mr_spedag_logo.svg';

import './Registration.scss';


class RegistrationForm extends Component {
    
    componentDidMount() {
        SetTitle('Registration');
    }

    goToRestrationPage = (withSIS = false) => {
        if (withSIS) {
            window.location.href = '/RegistrationWithSIS'
        }
        else {
            window.location.href = '/RegistrationWithoutSIS'
        }
    }
   
    render() {
        return (
            <div className="login-page">
                <Card bordered={false}>
                    <div className="RegistrationFormWrapper">
                        <div style={{ display: 'inline-block', fontSize: 'large', marginTop: '40%', marginRight: '10%', marginBottom: '30%', color: 'white' }}>{getText('msgHaveSISAccount')}</div>
                        <Button type="primary" onClick={this.goToRestrationPage.bind(this, true)} className="login-form-button" style={{ marginTop: '40%', marginRight: '10%', marginBottom: '30%'}}>
                            {getText('lbRegisterWithSIS')}
                        </Button>
                        <Button onClick={this.goToRestrationPage.bind(this, false)} className="login-form-button" style={{ marginTop: '40%', marginBottom: '30%' }}>
                            {getText('lbRegisterWithoutSIS')}
                        </Button>
                    </div>
                    <div className="card-login-caption">
                        <CompanyLogo className="companyLogo" />
                    </div>
                </Card>
            </div>
        );
    }
}

export default withTracker(RegistrationForm);
import 'url-polyfill';
import React, { Component } from 'react';
import Ajax from '../components/Ajax';
import withTracker from '../components/withTracker';
import { SetTitle } from '../components/Common';
import { saveAs } from "file-saver";

const dlUrl = `${process.env.PUBLIC_URL}/api/v1/File/DownloadPublicFile`;

class DownloadPublicFile extends Component {
    componentDidMount() {
        SetTitle('Download Public File');
        
        let urlParams = new URLSearchParams(window.location.search);
        let filename = urlParams.get("filename");
        
        this.downloadPublicFile(filename);
    }

    downloadPublicFile = async function (filename) {
        let url = new URL(dlUrl, window.location.href);
        url.searchParams.append('filename', filename);
        
        let res = await Ajax.get(`${dlUrl}/${filename}`, 'blob');

        if (res.data.size > 0) {
            var blob = new Blob([res.data], { type: "application/octet-stream" });
            blob.name = `${filename}.pdf`;
            saveAs(blob);
        }
    }
    render() {
        return <></>;
    }
}

export default withTracker(DownloadPublicFile);
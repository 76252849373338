import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';

const InformationIcon = ({ size, className }) => <QuestionCircleOutlined className={className} style={{ fontSize: size, verticalAlign: 'middle' }} />

InformationIcon.defaultProps = {
    size: '22px'
};

export default InformationIcon;

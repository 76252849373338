import React from 'react';
import AuthService from './AuthService';
import { isArray } from 'util';

const LoggedInUser = () => {
    const Auth = new AuthService();
    const userProfile = Auth.getProfile();
    //const { unique_name, profile } = userProfile;
    const { first_name, last_name, profile } = userProfile;

    const getProfile = () => {
        let res = '';
        const profileList = Auth.getProfileList();

        if (isArray(profileList) && profileList.length > 0) {
            try {
                res = profileList.find(l => l.profile_type_id === profile).profile_type;
            }
            catch {
                res = profile;
            }
        }
        else {
            res = profile;
        }

        return res;
    }

    return (
        <div>
            {`${first_name} ${last_name} (${getProfile()})`}
        </div>
    );
};

export default LoggedInUser;

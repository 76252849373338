const tc = {
    btnLogin: "登入",
    btnChangeUser: "Change User",
    msgLoginTimeout: "You have been logged off automatically after timeout.",
    msgLoginFail: "Incorrect username or password, please try again.",
    msgChangeUserFail: "Incorrect username or user is not activated",
    lbRegister: "Registration",
    lbRegisterWithSIS: "是",
    lbRegisterWithoutSIS: "否",
    lbForgotPW: "忘記密碼",
    lbResetPW: "重置密碼",
    lbTermsOfUse: "使用條款",//"條款和條件",
    lbTermsOfUseContent: "Legal Disclaimer: This system may not be used as the sole information tool by which future claims against any member of the M+R Spedag Group and its affiliates and/or agents services performance may be derived. Under no circumstances shall any member of the M+R Spedag Group and its affiliates and/or agents be liable for any damage including but not limited to special or consequential damages that result from the access or the use of, or the inability to access or use this system. The data contained in this system is provided without any warranty of any kind, either expressed or implied, as to their accuracy, reliability or completeness.",
    lbSignUp: "註冊",
    privacyPolicy: {
        header: "Privacy Policy",
        privacyPolicy1: "This privacy policy applies exclusively to the M+R Spedag Group website www.mrspedag.com.",
        privacyPolicy2: "Our goal is to ensure that your personal data is processed in compliance with the law. Our websites may contain links to external websites for which this data protection declaration is not valid.",
        personalData: "Personal data",
        personalDataContent: "We do not systematically collect personal data on our websites. It is possible that a visitor to our website transmits personal data to us, e.g. by using a contact form to contact us.",
        useOfPersonalData: "Use of personal data",
        useOfPersonalDataContent: "By submitting personal data via online forms, e-mail or similar, the user agrees to the processing of the data. This may be necessary to answer an enquiry or provide a service. If personal data is transmitted to us, it will only be used for internal processes and administered in compliance with legal requirements.",
        deletionOfPersonalData: "Deletion of personal data",
        deletionOfPersonalDataContent: "The data subject may request access to personal data and request any changes or deletion of personal data. The M+R Spedag Group will examine the request and implement it in accordance with legal requirements.",
        contract: "Contact",
        contractContent: "Information about your data can be sent to you via email: info@mrspedag.com",
        passongOfPersonalData: "Passing on of personal data",
        passongOfPersonalDataContent: "In principle, personal data will not be passed on to third parties. However, we reserve the right to take this step if this serves to comply with applicable laws and regulations and/or we consider this necessary to preserve our rights and/or to protect our property.",
        cookies: "Cookies",
        cookiesContent: "The full functionality of this website depends on the use of  cookies. These are small files that are stored on the visitor’s PC. Cookies are used to store certain settings or preferences. These cookies do not pose any risk to the user’s computer.",
        webAnalysisTools: "Web Analysis Tools",
        webAnalysisToolsContent: "Our website uses Google Analytics, a web analysis service of Google Inc. Information about the use of the websites, including the IP addresses of visitors, is transmitted to a Google server (usually in the USA) and stored there. This service is used to evaluate the use of the websites. Google provides add-ons for the most common web browsers under the web link “https://tools.google.com/dlpage/gaoptout”, with which Google Analytics can be deactivated.",
    },
    lbMR: "華隆瑞鋒貨運有限公司",
    lblConfirm: "確定",
    lblCancel: "取消",
    lblBack: "返回",
    lblEdit: "編輯",
    lblNext: "下一頁",
    lblSubmit: "Submit",
    lblSearch: "搜尋",
    lblYes: "是",
    lblNo: "否",
    lbUserID: "User ID",
    lbLoinID: "Login ID",
    lbPassword: "密碼",
    lbConfirmPassword: "確認密碼",
    msgConfirmPasswordRequired: "請確認密碼",
    msgPasswordInconsistent: "Two passwords that you enter is inconsistent",
    lbFamilyName: "姓",
    lbGivenName: "名",
    lbAddress: "地址",
    lbZipPostal: "郵政編碼",
    lbCountryCode: "國家代碼",
    lbEmail: "電郵",
    lbPhoneNumber: "電話",
    lbFax: "傳真",
    lbCompany: "公司",
    lbPosition: "職位",
    lbDepartment: "部門",
    lbLanguage: "語言",
    lbPageSize: "Page Size",
    btnSave: "儲存",
    btnCancel: "取消",
    lbContainerSize: "Container Size",
    lbQty: "Qty",
    lbCargoReadyDate: "貨物完成日期",
    lbDepartureFrom: "Departure From",
    lbPOL: "裝貨港",
    lbETD: "ETD",
    lbPOD: "卸貨港",
    lbETA: "ETA",
    lbMotherVesselVoyage: "Mother Vessel Voyage",
    lbCarrier: "Carrier",
    lbPLD: "提貨地點",
    lbFD: "最終目的地",
    lbCFS: "CFS (LCL)",
    lbCY: "CY",
    lbCyClosingDate: "CY Closing Date",
    lbVGM: "VGM",
    lbVgmCutOffDate: "VGM Cutoff Date",
    lbSI: "SI",
    lbSiCutOffDate: "SI Cutoff Date",
    lbServiceName: "Service Name",
    lbServiceCode: "Service Code",
    lbVesselName: "Vessel Name",
    lbVoyageCode: "Voyage Code",
    lbVesselImoNumber: "IMO Number",
    lbVesselOperator: "Operator",
    lbTransshipmentCode: "Transshipment Port",
    lbUserProfile: "User Profile",
    lbUserPassword: "User Password",
    lbNewPassword: "New Password",
    lbCurrentPassword: "Current Password",
    lbConfirmNewPassword: "Confirm New Password",
    lbContractType: "Contract Type",
    lbContractNo: "Contract No.",
    lbCarrierBookingNo: "Carrier Booking No.",
    lbCarrierConfirmBookingDate: "Carrier Confirm Booking",
    msgUpdatePwSuccessfully: "Updated password successfully",
    msgUpdatePwFail: "Fail to update password",
    msgPwNotMatch: "Password not match",
    msgUpdatedUserProfileSuccessfully: "Updated user profile successfully",
    msgUpdatUserProfileFail: "Fail to update user profile",
    msgUserProfileNotUnique: "Profile Type should be unique",
    msgUserProfileNotAssignedFuncRole: "Please assign at leaset 1 System Function Role for each profile",
    msgLoading: "載入中…",
    msgSaving: "儲存中…",
    errSaveUnknown: "Unable to save with unknown reason.",
    msgSureToDelete: "確定刪除？",
    msgNoRecordFound: "沒有資料",
    msgNoDashboardItem: "No dashboard item found.",
    msgLoadAutoSave: "An auto save record is found (saved at {{datetime}}), do you want to load it?",
    msgAutoSaveLoaded: "auto save loaded",
    msgAutoSaveSaved: "The booking has been auto-saved",
    msgSureToLeave: "Are you sure you want to leave? (tc)",
    errExportFailed: "Unable to export file.",
    msgSubmitting: "Submitting",
    msgSubmitForgotPwSuccessfully: "Submit successfully. An Email will be sent to reset password.",
    msgSubmitForgotPwFail: "No record found.",
    msgResetPwSuccessfully: "Reset successfully.",
    msgResetPwFail: "Fail to reset password.",
    msgRecordNotLatest: "This record is not updated. Record has been changed by another user.",
    msgSavedSuccess: "Saved",
    menu: {
        "Home": "主頁",
        "Dashboard": "Dashboard",
        "Booking": "Booking",
        "New e-Booking": "New e-Booking",
        "Cancel e-Booking": "Cancel e-Booking",
        "New Manual Booking": "New Manual Booking",
        "Booking Confirmed": "Booking Confirmed",
        "Booking Approval": "Booking Approval",
        "Request For Approval": "Request For Approval",
        "Pending For Customer Approval": "Pending For Customer Approval",
        "Booking Rejected": "Booking Rejected",
        "Booking Advice": "Booking Advice",
        "Setting": "設置",
        "User Profile": "User Profile",
        "Change Password": "更改密碼",
        "Admin": "Admin",
        "Party Maintenance": "Party Maintenance",
        "Sailing Schedule Maintenance": "Sailing Schedule Maintenance",
        "Sign Out": "登出",
        "ChangeUser": "Change User",
    },
    so: {
        lblHeader: "Header",
        lblDetails: "Details",
        lblISF: "ISF",
        lblDocumentUpload: "文件",
        lblSI: "Shipping Instruction",
        lblConfirmBooking: "Confirm Booking",
        lblShippingOrder: "Shipping ORDER",
        lblShipper: "托運人",
        lblShipperPlaceHolder: "Shipper (code)",
        msgShipperRequired: "Please select a Shipper!",
        lblCustomer: "客戶",
        lblCustomerPlaceHolder: "Customer (code)",
        msgCustomerRequired: "Please select a Customer!",
        lblConsignee: "收貨人",
        lblConsigneePlaceHolder: "Consignee (code)",
        msgConsigneeRequired: "Please select a Consignee!",
        lblMOT: "Mode of Transportation (tc)",
        msgMOTRequired: "Please select the Mode of Transportation!",
        lblMOTSea: "海運",
        lblMOTAir: "空運",
        lblMOTRail: "Rail",
        lblService: "托運類別",
        msgServiceRequired: "Please select a Service!",
        lblServiceFCL: "FCL",
        lblServiceLCL: "LCL",
        lblServiceBulk: "Bulk",
        lblPODDelivery: "Port / Door Delivery (tc)",
        msgPODDeliveryRequired: "Please select a Port / Door Delivery (tc)!",
        lblPODDeliveryP2p: "Port-to-Port",
        lblPODDeliveryP2d: "Port-to-Door",
        lblPODDeliveryD2p: "Door-to-Port",
        lblPODDeliveryD2d: "Door-to-Door",
        lblShipperReference: "Shipper Reference",
        lblShipperReferenceTooltip: "Reference no. of Shipper",
        lblShipperReferencePlaceHolder: "Shipper Reference",
        lblBusinessType: "Business Type (tc)",
        msgBusinessTypeRequired: "Please select a Business Type!",
        lblBusinessTypeNomination: "Nomination",
        lblBusinessTypeFreehand: "Freehand",
        lblNominationAgent: "Nomination Agent (tc)",
        msgNominationAgentRequired: "Please select a Nomination Agent!",
        lblAgentPlaceHolder: "Agent (code)",
        lblReleaseAgent: "Release Agent (tc)",
        msgReleaseAgentRequired: "Please select a Release Agent!",
        lbSOPInstruction: "SOP instructions (Chinese)",
        lbSOPInstructionContainerTitle: "Container Usage (Chinese)",
        lbSOPInstructionContainerContent: "Use of 20' needs approval by customer........",
        lbSOPInstructionRoutingTitle: "Routing Instructions (Chinese)",
        lbSOPInstructionRoutingContent: "1st priority carrier is........",
        msgFillInRequiredFields: "Please fill in all required fields.",
        errSONotFound: "Shipping Order ({{sono}}) is not found. (tc)",
        lblItemNo: "Item#",
        lblRemark: "備註",
        lblItemQty: "Item qty",
        lblItemQtyUnit: "Item qty unit",
        lblPackageQty: "Package qty",
        lblPackageQtyUnit: "Package qty unit",
        lblGrossWeight: "Gross weight",
        lblCBM: "立方米",
        lblDocumentType: "Document Type (tc)",
        lblFile: "File (tc)",
        lblFileSource: "File Source",
        lblDragOrUpload: "Select / Drag a file (tc)",
        lblTiming: "Timing as per customer's requirement (tc)",
        lblSubmitBooking: "Submit Booking",
        msgISFMustComplete: "對於出口至美國的貨物，請提供美國海關所需的ISF信息",
        lblSeller: "銷售商",
        lblManufacturer: "生產商",
        lblBuyer: "買方",
        lblShipTo: "運至",
        lblContainerStuffingLocation: "貨櫃裝箱地",
        lblConsolidator: "Consolidator",
        lblISFCompanyName: "公司全稱",
        msgSellerNameRequired: "Please input seller name!",
        lblSellerPlaceHolder: "銷售商公司全稱",
        msgManufacturerNameRequired: "Please input manufacturer name!",
        lblManufacturerNamePlaceHolder: "生產商公司全稱",
        msgBuyerNameRequired: "Please input buyer name!",
        lblBuyerPlaceHolder: "買方公司全稱",
        msgShipToNameRequired: "Please input ship to name!",
        lblShipToPlaceHolder: "公司全稱 ",
        msgContainerStuffingLocationNameRequired: "Please input container stuffing location name!",
        lblContainerStuffingLocationPlaceHolder: "Container Stuffing Location",
        msglblConsolidatorNameRequired: "Please input consolidator name!",
        lbllblConsolidatorPlaceHolder: "Consolidator Name",
        lblAddress1: "地址一",
        msgAddress1Required: "Please input address 1!",
        lblAddress1PlaceHolder: "地址一",
        lblAddress2: "地址二",
        msgAddress2Required: "Please input address 2!",
        lblAddress2PlaceHolder: "地址二",
        lblCity: "城市",
        msgCityRequired: "Please input city!",
        lblCityPlaceHolder: "城市",
        lblCountry: "國家",
        msgCountryRequired: "Please input country!",
        lblCountryPlaceHolder: "國家",
        lblCountryOfOrigin: "原產國",
        msgCountryOfOriginRequired: "Please input country of origin!",
        lblCountryOfOriginPlaceHolder: "原產國",
        lblHTSCode: "HTS Code",
        msgHTSCodeRequired: "Please input HTS code!",
        lblHTSCodePlaceHolder: "HTS Code",
        lblAddISFCompany: "新增{{type}}",
        lblRemoveISFCompany: "刪除{{type}}",
        colSoNo: "SO#",
        colPoNo: "PO#",
        colHblNo: 'HBL#',
        colShipper: 'Shipper',
        colConsignee: 'Consignee',
        colReleaseAgent: 'Release Agent',
        colNominationAgent: 'Nomination Agent',
        colBookingDate: 'Booking Date',
        colBookingStatus: 'Booking Status',
        colBookingStatusDate: 'Booking Status Date',
        colBookingApprovalStatus : 'Booking Approval Status',
        colBookingApprovalStatusDate : 'Booking Approval Date',
        colCargoReadyDate : 'Cargo Ready Date',
        colPol : 'POL',
        colEtd : 'ETD',
        colPod: 'POD',
        colPodEta: 'POD ETA',
        colCustomer: 'Customer',
        colTradelane: 'Tradelane',
        colBookingUpdatedBy: 'Booking Updated By',
        colBookingLastUpdatedTime: 'Booking Last Updated Time',
        colBookingApprovalReason: 'Booking Approval Reason',
        colBookingApprovalRemarks: 'Booking Approval Remarks',
        colBookingApprovalUpdatedBy: 'Booking Approval Updated By',
        colReference: 'Reference',
        colLatestStatus: 'Latest Status',
        miReactivateBooking: "Recover Booking",
        msgSureToReactivateBooking: "Are you sure you want to recover the booking?",
        miAcknowledgeCancelBooking: "Acknowledge Cancel",
        msgSureToAcknowledgeCancelBooking : "Are you sure you want to acknowledge the cancellation request?",
        miRejectCancelBooking : "Reject Cancel",
        msgSureToRejectCancelBooking: "Are you sure you want to reject the cancellation request?",
        miCancelBooking: "Cancel Booking",
        msgSureToCancelBooking: "Are you sure you want to cancel the booking?",
        lblBookingApproval: "Booking Approval",
        colRemarks: "Remarks",
        colRemarkType: "Remark Type",
        colVisibleTo: "Visible To",
        colApprovalStatus: "Approval Status",
        lbApprovalStatus: "Approval Status",
        lbNewRemarks: "New Remarks",
        lbVisibleTo: "Visible To",
        lbReasons: "Reasons",
        cbSendEmailNotifyTo: "Send Email Notification To",
        msgAddBookingApprovalSuccess: "Add Booking Approval Successfully",
        msgAddBookingApprovalFail: "Fail to add Booking Approval",
        lbSONo: "SO#",
        lbHBLNo: "HBL#",
        lbTrader: "Trader",
        lbNotifyParty: "Notify Party",
        lbHandlingOffice: "Handling Office",
        lbContact: "Contact",
        lbContactPhone: "Phone",
        lbPOR: "POR",
        lbPOL: "POL",
        lbPOD: "POD",
        lbPLD: "PLD",
        lbPOLEtd: "POL ETD",
        lbPLDEta: "PLD ETA",
        lbFD: "FD",
        lbFDEta: "FD ETA",
        lbCargoReadyDate: "Cargo Ready Date",
        msgInvalidEmail: "Invalid email",
        msgEmptyRemark: "Please enter remark details or close the remark",
        msgFillRequire: "Please fill in the required column(s)",
        msgEmptyRemarkType: "Please enter New Remarks for {{i}} remark",
        msgUserContactEmailSuccess: "{{email}} is deleted successfully",
        msgUserContactEmailFail: "Fail to delete {{email}}",
        lbWarehouse: "Warehouse (LCL)",
        lbWarehouseSONo: "Warehouse SO No.",
        msgWarningEditBookingApproval: "Please save the existing booking or cancel edit before performing Booking Approval",
        msgDeleteISFProfileSuccess: "Deleted successfully",
        msgDeleteISFProfileFail: "Delete fail",
        msgFillInRequiredTabFields: "Please revise highlighted tab(s)",
        msgConfirmAcceptChangeMsg: 'Accept changes will replace all containers and all allocation between PO and containers will be deleted.Are you sure to continue?',
        colDefaultAgentCode: 'Code',
        colDefaultAgentName: 'Name',
        colDefaultAgentIsAutoFill: 'Default',
        colDefaultAgentDescription: 'Description',
        lblDefaultAgentConsigneeCode: 'Consignee Code',
        lblDefaultAgentCustomer: 'Customer',
        lblDefaultNominationAgentCode: 'Related Nomination Agent Codes',
        lblDefaultReleaseAgentCode: 'Related Release Agent Codes',
        polCfsClosingRequired: 'Please input CFS!',
        polCyClosingRequired: 'Please input CY!',
        polVgmClosingRequired: 'Please input VGM!',
        polSiClosingRequired: 'Please input SI!',
        msgSendEmailPopup: "Booking Number {{so_no}} will be saved.Do you want to send a BMS booking email notification to shipper, booking cc email and M + R OP ?",
        lbConfirmSendEmail: "Yes & Save the Change",
        lbConfirmNotSendEmail: "No & Save the Change",
        lblSOStatus: "Status",
        pnlStatusHistory: "Status History",
        colSOStatus: "Status",
        colSOStatusAction: "Action",
        colSOStatusRemark: "Remark",
        colSOStatusUser: "User",
        colSOStatusUpdatedTime: "Updated Time",
        msgIncorrectExFactoryDate: "The Ex-Factory Date of Container# {{container_no}} must NOT be earlier than Cargo Ready Date ({{cargo_ready_date}})",
        msgMissingExFactoryDate: "Please enter the Ex-Factory Date for Container# {{container_no}}",
        msgMissingSealNO: "Please enter the Seal# for Container# {{container_no}}",
        msgMissingCarrierSONO: "Please enter the Carrier Booking# for Container# {{container_no}}",
        msgMissingPackageQTY: "Please enter the Package QTY for Container# {{container_no}}",
        msgExceedsGW: "Container #{{container_no}}: The difference between allocated GW ({{allocatedGW}}) and entered in container GW ({{containerGW}}) cannot exceeds 1",
        msgExceedsCBM: "Container #{{container_no}}: The difference between allocated CBM ({{allocatedCBM}}) and entered in container CBM ({{containerCBM}}) cannot exceeds 1",
        msgResendEmailSuccess: "Resend S/O Sheet by email successfully",
        lbCopyToMenufacturer: "Copy to Manufacturer",
        lbCopyToContainerStuffingLocation: "Copy to Container Stuffing Location",
        lbCopyToShipTo: "Copy to Ship To",
        lbCopyToConsolidator: "Copy to Consolidator",
        lbShowFullHDOffice: "Show Full List Handling Office",
        msgETDLaterThanCargoReadyDate: "ETD should be later than the Cargo Ready Date. Please check and amend the ETD and/ or the Cargo Ready Date",
        msgETDMoreThan14Days: "ETD is more than 14 days after Cargo Ready Date. Please check the ETD and the Cargo Ready Date. Click \"OK\" to continue"
    },
    lbSOColorDesc: {
        newEBookgingRed: "Pending for confirmation more than 18 hours",
        newEBookgingYellow: "Pending for confirmation more than 8 hours",
        newEBookgingGreen: "Pending for confirmation",
        amendEBookgingRed: "Pending for confirmation more than 18 hours",
        amendEBookgingYellow: "Pending for confirmation more than 8 hours",
        amendEBookgingGreen: "Pending for confirmation",
        cancelEBookgingRed: "Pending for acknowledgement more than 18 hours",
        cancelEBookgingYellow: "Pending for acknowledgement more than 8 hours",
        cancelEBookgingGreen: "Pending for acknowledgement",
        newManualBookgingRed: "Pending for acknowledgement more than 18 hours",
        newManualBookgingYellow: "Pending for acknowledgement more than 8 hours",
        newManualBookgingGreen: "Pending for acknowledgement",
        confirmBookingRed: "Confirmed Booking pending for booking approval for more than 2 days",
        confirmBookingYellow: "Confirmed Booking pending for booking approval for more than 1 day",
        confirmBookingGreen: "Confirmed Booking pending for booking approval",
        requestForApprovalRed: "Pending on requesting for approval more than 2 days",
        requestForApprovalYellow: "Pending on requesting for approval more than 1 day",
        requestForApprovalGreen: "Pending on requesting for approval",
        pendingForCustomerApprovalRed: "Pending on getting customer approval more than 2 days",
        pendingForCustomerApprovalYellow: "Pending on getting customer approval more than 1 days",
        pendingForCustomerApprovalGreen: "Pending on getting customer approval",
        bookingRejectedRed: "Booking was rejected more than 1 day",
        bookingRejectedYellow: "Booking was rejected"
    },
    sysNotice: {
        lbNoticeType: "Notice Type",
        lbNoticeTitle: "Notice Title",
        lbNoticeBody: "Notice Body",
        lbMod: "Affected System",
        lbValidDate: "Valid Date",
        lbRemarks: "Remarks",
        lbCreatedBy: "Created By",
        lbCreatedDate: "Create Date",
        lbUpdatedBy: "Updated By",
        lbUpdatedDate: "Updated Date",
        msgUpdatedSysNoticeSuccessfully: "Updated System Notice Successfully",
        msgUpdateSysNoticeFail: "Fail to update System Notice",
        msgConfirmDelete: "Are you sure to delete this system notice?",
        lbDelete: "Delete",
        lbCopy: "Copy"
    },
    lbParty: "Party",
    lbPartyID: "Party ID",
    lbPartyCode: "Party Code",
    lbOtherCode: "Other Code",
    lbPartyType: "Party Type",
    lbContactPerson: "聯絡人",
    lbCity: "城市",
    lbState: "州",
    lbShortName: "Short Name",
    lbFullName: "Full Name",
    lbStatus: "Status",
    msgUpdatePartySuccess: "Updated party successfully",
    msgUpdatePartyFail: "Fail to update party",
    lbTradeLaneID: "TradeLane ID",
    msgCannotMapUserProfiles: "Cannot map user profile with SIS ID, please register a new account without SIS ID",
    msgLoginIDAleadyRegistered: "Login ID already registered",
    msgSISLoginIDRequire: "Please enter SIS Login ID",
    lbTradeLaneName: "TradeLane Name",
    lbOfficeCode: "Office Code",
    lbEffectiveDate: "Effective Date",
    lbTradeLane: "TradeLane",
    lbCustomerTypeID: "Customer Type ID",
    lbCustomerCode: "Customer Code",

    msgUpdateTradeLaneSuccess: "Updated TradeLane successfully",
    msgUpdateTradeLaneFail: "Fail to update TradeLane",
    msgConfirmDeleteTradeLane: "Are you sure want to delete TradeLane [{{tradelane_name}}]?",
    msgDeleteTradeLaneSuccess: "Deleted TradeLane successfully",
    msgDeleteTradeLaneFail: "Fail to delete TradeLane",

    lbPartyGroup: "Party Group",
    lbPartyGroupID: "Party Group ID",
    lbGroupName: "Group Name",
    lbGroupType: "Group Type",
    msgUpdatePartyGroupSuccess: "Update party group successfully",
    msgUpdatePartyGroupFail: "Fail to update party group",
    msgPartyGroupMissingField: "Please fill in the missing fields",



    lbInvErr: {
        lbNoPermission: "You have no permission",
        lbPleaseSelect: "Please Select!",
        lbPleaseInput: "Please Input!",
        //lbNoZero: "Cannot be 0",
        //lbMaxValueErr: "Please input value less than (maxNum)",
        //lbMinValueErr: "Please input value greater than (minNum)",
        lbOutOfRangeErr: "Please input value between (minNum) and (maxNum)",
        lbJobNoNotFound: "This job no. cannot be found.",

        lbMissingSO: '試中文',
    },
    notification: {
        lblFollow: 'Follow',
        lblFollowing: 'Following',
        lblUserDefaultSettingTitle: 'User Default Notification Setting',
        lblShipperOrderSettingTitle: 'Notification Setting for Shipping Order : ',
        lblMasterSettingTitleBooking: 'Booking',
        lblMasterSettingTitleWebInvoice: 'Web Invoice',
        lblMasterSettingTitleBookingApproval: 'Booking Approval',
        lblFollowTitle: 'You can change the setting by choosing:',
        lblFollowOption1: 'User Default',
        lblFollowOption2: 'Customized',
        lblDefaultSelection: 'Default Selection',
        lblSettingDesc1: 'You will be notified for the following events',
        lblSettingDesc2: 'By :',
        lblEventTableColumnEvent: 'Event',
        lblNotificationMethodOption1: 'In-app notification',
        lblNotificationMethodOption2: 'Email notification',
        bellButtonToolTip: 'Notification',
        lblNotificationListTitle: 'Notification',

        smartSearchTooltip: "Enter SO# or CONSOL# to search. \n\nOnce you entered the keyword in this field, you could click 'Tab' and then press 'Enter' to perform searching.",

        lblNotificationListMenuOption1: 'Mark All as Read',
        lblNotificationListMenuOption2: 'Delete All',
        lblNotificationListMenuOption3: 'Refresh',
        lblNotificationListMenuOption4: 'User Default Setting',

        lblNotificationListItemMenuOption1a: 'Mark as Read',
        lblNotificationListItemMenuOption1b: 'Mark as Unread',
        lblNotificationListItemMenuOption2: 'Delete Notification',
        lblNotificationListItemMenuOption3a: 'Unfollow SO# ',

        msgSureToDeleteAll: "Sure to delete all notification?",

        lblNotificationViewAll: 'View all',

        msgAtLeastOneEvent: 'Please select at leaset 1 notification event.',
        msgAtLeastOneNotificationMethod: 'Please select at leaset 1 notification method.',
        msgMissingEmailAddress: 'Please Fill in Email address.',
        msgInvalidEmailAddress: 'Invalid email',
        
        lblEditUserDefaultSettingTooltip: 'Edit',
        lblResetBtn: 'Reset Selection'
    }
};

export default tc;
import React from 'react';
import { Table } from 'antd';

import './Table.scss';

const calcScroll = (columns, defaultColWidth, maxHeight, scroll) => {
    if (!columns || columns.length === 0) return null;
    let x = columns.map(c => c.width || defaultColWidth).reduce((a, c) => a + c);
    //return { x };
    //return { x, y: 450 };
    return Object.assign({ x, y: maxHeight }, scroll);
};

const getRowClassName = (rowClassName) => (
    (record, index) => {
        let className = index % 2 === 0 ? '' : 'altRow';
        if (rowClassName && typeof rowClassName === 'function') {
            className = ` ${rowClassName(record, index)}`;
        }
        return className;
    }
);

export const pageSizeOptions = ['10', '20', '30', '40', '50', '100'];

const ListTable = ({ columns, dataSource, error, pagination, onChange, isLoading, defaultColWidth, rowKey, size, rowClassName, maxHeight, scroll, ...props }) => {
    let errMsg = "";
    let msgType = "info";

    if (error) {
        errMsg = error;
        msgType = "danger";
    }

    if (errMsg && errMsg.length > 0 && !isLoading) {
        return (
            <div className={`alert alert-${msgType}`} role="alert">
                {errMsg}
            </div>
        );
    }

    const paginationWithDefaults = typeof pagination === 'boolean' ? pagination : Object.assign({ pageSizeOptions: pageSizeOptions}, pagination);

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            rowKey={rowKey}
            scroll={calcScroll(columns, defaultColWidth, dataSource && dataSource.length > 0 ? maxHeight : undefined, scroll)}//{dataSource && dataSource.length > 0 ? calcScroll(columns, defaultColWidth, maxHeight, scroll) : undefined}
            pagination={paginationWithDefaults}
            loading={isLoading}
            onChange={onChange}
            size={size}
            rowClassName={getRowClassName(rowClassName)}
            {...props}
        />
    );
};

ListTable.defaultProps = {
    defaultColWidth: 100,
    size: "middle",
    maxHeight: 450
};

export default ListTable;

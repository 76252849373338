import React from 'react';
import Ajax from '../../components/Ajax';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import './SelectEnhance.scss';
import { isArray } from 'util';

const Option = Select.Option;
const getInSelectionOptionsUrl = `${process.env.PUBLIC_URL}/api/v1/Master/GetInSelectionOptions`

class SelectEnhance extends React.PureComponent {

    constructor(props) {
        super(props);
        
        this.handleOnSearch = debounce(this.fetchOptions, 1500);
        this.state = {
            fetching: false,
            options: null,
            processdReload: false
        }
    }

    componentDidMount() {
        const { value, children } = this.props;

        if (isArray(value) && value.length > 0) {
            this.fetchOptions(null, value.join(', '));
        }
        else if (value > 0 || (value || '').toString().length > 0) {
            //mingsing 20230519: add checking, if children already contains value, no need to call ajax to get the list again
            if (isArray(children) && children.length > 0) {
                let idx = -1;
                idx = children.findIndex(c => c.props && c.props.value === value);

                if (idx === -1) {
                    this.fetchOptions(null, value);
                }
            }
            else {
                this.fetchOptions(null, value);
            }
            //end mingsing 20230519
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { value: prevValue, fetchOptionKey: prevFetchOptionKey } = prevProps
        const { value, fetchOptionKey, reloadSelectList } = this.props;
        const { options: preOptions } = prevState;
        const { options, processdReload } = this.state;
        
        if (!!reloadSelectList && value !== undefined && fetchOptionKey && !processdReload) {
            this.setState({ processdReload: true }, () => {
                this.fetchOptions(null, value.toString())
            });
        }
        else if (isArray(prevValue) && isArray(value) && prevValue.length !== value.length && !isArray(options) || prevFetchOptionKey !== fetchOptionKey) {
            this.fetchOptions(null, value.toString())
        }
        else if (prevValue !== value && prevValue === undefined && value !== undefined && fetchOptionKey) {
            this.fetchOptions(null, value.toString())
        }
        else if ((value !== undefined || (isArray(value) && value.length > 0)) && (!isArray(options) || (isArray(options) && options.length === 0)) && isArray(preOptions) && preOptions.length > 0 && fetchOptionKey) {
            this.fetchOptions(null, value.toString())
        }
    }

    fetchOptions = async (searchText, searchValue) => {
        const { fetchOptionCallBack, fetchOptionKey, skipDataAccessCheck } = this.props;
        this.setState({ fetching: true });
        let options = null;
        
        if (((searchText && searchText.length >= 2) || (searchValue && searchValue.toString().length >= 1)) && (fetchOptionKey||'') !== '') {
            let params = {
                fetchOptionKey: fetchOptionKey,
                searchText,
                searchValue,
                skipDataAccessCheck
            };

            let response = await Ajax.post(`${getInSelectionOptionsUrl}`, params);
            options = response.data;
        }

        this.setState({ options, fetching: false, processdReload: false }, fetchOptionCallBack && fetchOptionCallBack(options));
    }

    handleOnBlur = () => this.setState({ options: null });

    render() {
        const { fetching, options } = this.state;
        const { onSearch, fetchOptionKey, placeholder, filterOption, children, fetchOptionCallBack, reloadSelectList, skipDataAccessCheck, ...other } = this.props;
        
        return (
            <Select
                placeholder={!placeholder ? "..." : placeholder}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                onSearch={this.handleOnSearch}
                onBlur={this.handleOnBlur}
                filterOption={filterOption == null ? ((input, option) => option.children == null || option.children.toLowerCase().indexOf(input.toLowerCase()) > -1) : filterOption}
                {...other}
            >
                {!(isArray(options) && options.length > 0) && children}
                {isArray(options) && options.map(option => (<Option key={option.value} value={option.value}>{option.text}</Option>))}
            </Select>
        );
    }
}

SelectEnhance.propTypes = {
    fetchOptionKey: PropTypes.string,
    skipDataAccessCheck: PropTypes.bool
}

SelectEnhance.defaultProps = {
    skipDataAccessCheck: false
}

export default SelectEnhance;
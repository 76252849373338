import React, { PureComponent } from 'react';
import { SetTitle } from '../components/Common';

class AccessDeclined extends PureComponent {
    componentDidMount() {
        SetTitle('403 Forbidden');
    }

    render() {
        return (
            <div>
                <h1>403 Forbidden</h1>
                <p>You don't have permission to access {this.props.location.pathname.split('/').pop() === 'AccessDeclined' ? 'the record' : '(' + this.props.location.pathname + ')'}.</p>
            </div>
        );
    }
}

export default AccessDeclined;
import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

class DatePickerWrapper extends DatePicker  {
    render() {
        let { defaultValue, defaultPickerValue, value, ...props } = this.props;
        defaultValue = defaultValue ? moment(defaultValue) : null;
        defaultPickerValue = defaultPickerValue ? moment(defaultPickerValue) : null;
        value = value ? moment(value) : null;
        return <DatePicker defaultPickerValue={defaultPickerValue} defaultValue={defaultValue} value={value} {...props} />;
    }
}

export default DatePickerWrapper;

import React, { Component } from 'react';

import { Form4, Input, Message, Card, Col, Row, Button, Layout, Steps, Result, Notification, Spin, Select, Modal, Tooltip, AutoComplete } from '../../components/base';
import { getText } from '../../i18n/i18next';
import Ajax from '../../components/Ajax';
import { SetTitle, checkStrongPassword, CheckIsNullOrUndefined } from '../../components/Common';
import EditButton from '../../components/EditButton';
import withTracker from '../../components/withTracker';
import { ReactComponent as CompanyLogo } from '../../img/mr_spedag_logo.svg';
import * as qs from 'query-string';
import history from '../../components/history';
import InformationIcon from '../../components/InformationIcon';
import { GetCountryOption } from '../ShippingOrder/common';
import './RegistrationWithoutSIS.scss';
import FlagIcon from '../../components/FlagIcon';

const FormItem4 = Form4.Item;
const { Option } = Select;
const { Header, Footer } = Layout;

const { Step } = Steps;

const steps = [
    {
        title: 'Enter Details'
    },
    {
        title: 'Email Verification'
    },
    {
        title: 'Set Password'
    },
    {
        title: 'Registration Result'
    },
    //{
    //    title: 'Activate Result'
    //}
];

const locationGroup = [ ['SCH'], ['NCH'], ['SEA', 'LPA', 'IND'] ] 

//const activateAccountUrl = `${process.env.PUBLIC_URL}/api/v1/admin/activateAccount`;
const getPasswordConfig = `${process.env.PUBLIC_URL}/api/v1/Password/getPasswordConfig`;
const updatePassword = `${process.env.PUBLIC_URL}/api/v1/Admin/UpdateNewUserPassword`;
const baseUrl = `${process.env.PUBLIC_URL}/api/v1`;
const inputDelayTimeinSec = 0.3;
const countryFlagStyle = {
    lineHeight: '39.9999px'
};

const displayErrorMsg = (errorMsg = []) => {
    if (errorMsg.length > 0) {
        return (
            <div>
                <ul>
                    {errorMsg.map((msg, index) => <li key={index}>{msg}</li>)}
                </ul>
            </div>
        );
    }
    else return '';
};

class RegistrationWithoutSIS extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            current: 0,
            loading: false,
            confirmDirty: false,
            user: {},
            userType: "",
            userTypeOption: [],
            LocationOption: [],
            officeOption: [],
            fdcList: [],
            //activateResultTitle: '',
            //activateResultStatus: '',
            //activateResultMsg: '',
            modalTermsOfUseVisible: false,
            strongPWRequired: false
        };

        this.formRef = React.createRef();
    }

    componentDidMount() {
        SetTitle('Registration');

        const parsed = qs.parse(this.props.location.search);

        if (parsed && parsed.loginID && parsed.token) {
            this.setState({ current: 2 });
        }
        this.getOffice();
        this.getUserTypeOpts();
        this.getLocationOpts();
        this.getPasswordConfig();
    }


    goToLoginPage = () => {
        window.location.href = '/login'
    }

    handleBack = (e) => {
        window.onbeforeunload = undefined;
        history.goBack();
    }

    handleDropdownChange = async (controller, type, fieldName, value, callback, keepValue = false) => {
        if (value !== undefined && value !== null) {
            let url = new URL(`${baseUrl}/${controller}/${type}/${value}`, window.location.href);
            if (keepValue)
                url.searchParams.append('includeValue', true);

            try {
                let res = await Ajax.get(url.toString());
                let data = res.data;
                this.setState({ [`${fieldName}List`]: data }, () => {
                    if (callback && typeof callback === "function") {
                        callback(data);
                    }
                });
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    let data = [];
                    if (keepValue) {
                        if (type === 'port')
                            data.push({ "name": value, "unCode": value, "countryCode": "", "countryName": "" });
                        else
                            data.push(value);
                    }
                    this.setState({ [`${fieldName}List`]: data });
                }
            }
        }
    }

    handleCountryCodeChange = (value, callback) => {
        if (this.fdcChangetimeoutID !== null) clearTimeout(this.fdcChangetimeoutID);
        this.fdcChangetimeoutID = setTimeout(() => {
            this.handleDropdownChange('master', 'country', 'fdc', value, callback);
        }, inputDelayTimeinSec * 1000);
    }

    handleCountryCodeOnSelect = (e) => {
        const { user } = this.state;

        user['co_country_code'] = e;

        this.setState({
            user
        });
    }

    getOffice = () => {
        Ajax.get(`${process.env.PUBLIC_URL}/api/v1/admin/getofficeopts`)
            .then(res => {
                if (res && res.data && res.data && res.data.length > 0) {
                    this.setState({ officeOption: res.data });
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {

                }
            });
    }

    filterOfficeByLocation = () => {
        const { current } = this.formRef;
        const location_type_id = current && current.getFieldValue('location_type_id'), currentLocationType = locationGroup[location_type_id-1];
        let officeOption = this.state.officeOption;
        var resArr = [];

        if(!location_type_id || location_type_id === 0) return ;

        officeOption = officeOption.filter(office => currentLocationType.includes(office.group_id));

        for (var i = 0; i < officeOption.length; ++i) {
            resArr.push(<Option key={officeOption[i].party_id} value={officeOption[i].party_id}>{officeOption[i].short_name}</Option>);
        }

        return resArr;
    }

    getUserTypeOpts = () => {
        Ajax.get(`${process.env.PUBLIC_URL}/api/v1/admin/getusertypeopts`)
                .then(res => {

                    if (res && res.data) {
                        let resDat = res.data, resArr = [];

                        for (let i = 0; i < resDat.length; ++i) {
                            resArr.push(<Option key={resDat[i].pkey} value={resDat[i].pkey}>{resDat[i].pvalue}</Option>);
                        }

                        this.setState({ userTypeOption: resArr });
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {

                    }
                });
    }

    getLocationOpts = () => {
        Ajax.get(`${process.env.PUBLIC_URL}/api/v1/admin/getlocationopts`)
                .then(res => {
                    
                    if (res && res.data) {
                        let resDat = res.data, resArr = [];
                        for (let i = 0; i < resDat.length; ++i) {
                            resArr.push(<Option key={resDat[i].pkey} value={resDat[i].pkey}>{resDat[i].pvalue}</Option>);
                        }

                        this.setState({ locationOption: resArr });
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {

                    }
                });
    }

    updatePassword = async (password) => {
        const parsed = qs.parse(this.props.location.search);

        if (parsed && parsed.loginID && parsed.token) {
            let user = { login_id: parsed.loginID, password: password, token: parsed.token };
            await Ajax.post(`${updatePassword}`, user)
        }
        else {
            Message.error('Invalid login email or token');
        }
    }

    //activateAccount = async (loginID, token) => {
    //    let url = new URL(activateAccountUrl, window.location.href);
    //    url.searchParams.append('loginid', loginID);
    //    url.searchParams.append('token', token);

    //    try {
    //        let res = await Ajax.get(url);

    //        if (res && res.data && res.data.success) {
    //            this.setState({
    //                activateResultTitle: 'Activate Account Successfully',
    //                activateResultStatus: 'success',
    //            })
    //        }
    //        else {
    //            this.setState({
    //                activateResultTitle: 'Fail to Activate Account',
    //                activateResultStatus: 'error',
    //                activateResultMsg: 'Acoount has been activated already or activate period exceeds 7 days'
    //            })
    //        }
    //    }
    //    catch (err) {
    //        this.setState({
    //            activateResultTitle: 'Fail to Activate Account',
    //            activateResultStatus: 'error',
    //            activateResultMsg: err && err.response && err.response.data ? err.response.data.Message : ''
    //        })
    //    }
    //}

    getPasswordConfig = async () => {
        let url = new URL(getPasswordConfig, window.location.href);

        let result = await Ajax.get(url);

        this.setState({ strongPWRequired: result && result.data && result.data.success ? result.data.useStrongPassword : false });
    }

    validateToNextPassword = async (rule, value) => {
        const { strongPWRequired } = this.state;

        if (strongPWRequired && !CheckIsNullOrUndefined(value) && value !== '' && !checkStrongPassword(value)) {
            throw new Error(getText('msgStrongPWRequired'));
        }
    }

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }

    handleUserTypeChange = e => {
        if (e !== 'SHIPPER'){
            const { current } = this.formRef;

            current && current.setFieldsValue({
                location_type_id: 0,
                referred_by_office_party_id: 0
            });
        }

        this.setState({ 
            userType: e
        });
    }

    handleLocationChange =  e => {
        this.setState({ location: e });
    }

    validate = async (values) => {
        let url = new URL(`${process.env.PUBLIC_URL}/api/v1/admin/validateuserprofile`, window.location.href);
        url.searchParams.append('login_id', values.login_id);
        url.searchParams.append('checkIsRegistered', true);
       
        try {
            let result = await Ajax.get(url)
            
            return result.data;
        }
        catch (error) {
            Message.error(`${error}`);
            return false;
        }
    }

    doCheckValidCode = (value, codeList, field) => {
        if (CheckIsNullOrUndefined(value) || value === '') return true;

        let len = codeList.length;
        let validCode = false;

        if (len <= 0) {
            return false;
        }

        for (var i = 0; i < len; ++i) {
            if (codeList[i][field] === value) {
                validCode = true;
                break;
            }
        }
       
        return validCode;
    }

    onFinish = async (values) => {
        const { current } = this.state;

        this.setState({ loading: true });

        if (current === 0) {
            let user = Object.assign({}, this.state.user, values, { email2: values.login_id });
            if (user.location_type_id === 0) {
                user.location_type_id = null;
            }
            if (user.referred_by_office_party_id === 0) {
                user.referred_by_office_party_id = null;
            }
            user.status = 'P';

            let res = await this.validate(user);

            if (res) {
                if (res.length > 0) {

                    let title = 'Error';
                    if (values)
                        title = values.login_id;
                    let errorMessages = [];
                    res.forEach((r) => {
                        errorMessages.push(getText(r.msg_key));
                    });

                    Notification.error({
                        message: title,
                        description: displayErrorMsg(errorMessages),
                        placement: 'bottomRight',
                        duration: 0
                    });
                }
                else {
                    try {
                        let upsertRes = await Ajax.post(`${process.env.PUBLIC_URL}/api/v1/admin/registerapplication`, user)

                        let result = upsertRes.data;

                        if (!result) {
                            Message.error(getText('errSaveUnknown'));
                        }

                        this.setState({ current: (current + 1) % 4 });
                    }
                    catch (err) {
                        this.setState({
                            loading: false
                        }, () => { Message.error(getText('errSaveUnknown')); });
                    }
                }
            }
        }
        else if (current === 2) {
            await this.updatePassword(values.password);

            this.setState({ current: (current + 1) % 4 });
        }

        this.setState({ loading: false });
    }

    onFinishFailed = ({ values, errorFields, outOfDate }) => {
        Message.error('one or more field types are not input properly');
    }

    setModalTermsOfUseVisible = modalTermsOfUseVisible => this.setState({ modalTermsOfUseVisible });
    
    render() {
        const { current, user, fdcList /*activateResultTitle, activateResultMsg, activateResultStatus*/ } = this.state;
        
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 }
            }
        };

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 18, offset: 6 }
            }
        };
        const rootClassName = `root`;
        const now = new Date();
        const fdcOptions = GetCountryOption(fdcList);
        const routingFormColLayout = {
            dropdown: {
                xs: 24,
                sm: 24,
                lg: 19,
                xl: 19
            },
            dropdown1: {
                xs: 22,
                sm: 22,
                lg: 16,
                xl: 16
            },
            dropdown2: {
                xs: 4,
                sm: 4,
                lg: 2,
                xl: 2
            },
            flag: {
                xs: 24,
                sm: { span: 12, offset: 12 },
                lg: { span: 4, offset: 0 },
                xl: 4
            }
        };

        return (
            <Layout className={rootClassName}>
                <Header className="header">
                    <CompanyLogo className="companyLogo" />
                </Header>
                <Layout className="main">
                    <Spin spinning={this.state.loading}>
                        <Card>
                            <div style={{ margin: '5em 0' }}>
                                <Steps current={current}>
                                    {steps.map(item => (
                                        <Step key={item.title} title={item.title} />
                                    ))}
                                </Steps>
                            </div>
                            <div style={{
                                margin: '2em 0'
                            }}>
                                <EditButton
                                    editable={true}
                                    onCancel={this.handleBack}
                                    onEdit={this.handleBack}
                                    cancelText={getText('lblBack')}
                                    editText={getText('lblBack')}
                                />
                            </div>
                            <Form4
                                onFinish={this.onFinish}
                                onFinishFailed={this.onFinishFailed}
                                ref={this.formRef}
                                name='regWithoutSISForm'
                            >
                                {
                                    current === 0 &&
                                    <div>
                                        <Row gutter={16} type="flex" justify="start">
                                            <Col span={14}>

                                                <FormItem4
                                                    {...formItemLayout}
                                                    label={(
                                                        <span>
                                                            {getText('lblUserType')}
                                                        </span>
                                                    )}
                                                    name='user_type_id'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: getText('Please choose an user type')
                                                        }
                                                    ]}
                                                >
                                                        <Select onChange={this.handleUserTypeChange}>
                                                            {this.state.userTypeOption}
                                                        </Select>
                                                </FormItem4>
                                                <FormItem4
                                                    {...formItemLayout}
                                                    label={<span><Tooltip title={getText('lblLocationToolTip')}>
                                                        {getText('lblLocation')} <InformationIcon />
                                                    </Tooltip></span>}
                                                    name='location_type_id'
                                                >
                                                        <Select disabled={this.state.userType !== 'SHIPPER'} onChange={this.handleLocationChange}>
                                                            <Option key={0} value={0}> &nbsp; </Option>
                                                            {this.state.locationOption}
                                                        </Select>
                                                </FormItem4>
                                                <FormItem4
                                                    {...formItemLayout}
                                                    label={<span><Tooltip title={getText('lblReferredToolTip')}>
                                                        {getText('lblReferred')} <InformationIcon />
                                                    </Tooltip></span>}
                                                    name='referred_by'
                                                >
                                                         <Input />
                                                </FormItem4>
                                                <FormItem4
                                                    {...formItemLayout}
                                                    label={<span><Tooltip title={getText('lblReferredOfficeToolTip')}>
                                                        {getText('lblReferredOffice')} <InformationIcon />
                                                    </Tooltip></span>}
                                                    style={this.state.userType !== 'SHIPPER' ? { display: 'none' } : {}}
                                                    name='referred_by_office_party_id'
                                                >
                                                        <Select>
                                                            <Option key={0} value={0}> &nbsp; </Option>
                                                            {this.filterOfficeByLocation()}
                                                        </Select>
                                                </FormItem4>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} type="flex" justify="start">
                                            <Col span={14}>

                                                <FormItem4
                                                    {...formItemLayout}
                                                    label={<span><Tooltip title={getText('lbLoinIDToolTip')}>
                                                        {getText('lbLoinID')} <InformationIcon />
                                                    </Tooltip></span>}
                                                    name='login_id'
                                                    rules={[
                                                        {
                                                            type: 'email',
                                                            message: 'Please enter an email address as your login ID',
                                                        },
                                                        {
                                                            required: true,
                                                            message: getText('msgLoinIDRequired')
                                                        }
                                                    ]}
                                                >
                                                        <Input autoComplete="new-password" />
                                                </FormItem4>
                                            </Col>
                                        </Row>

                                        <Row gutter={16} type="flex" justify="start">
                                            <Col span={14}>
                                                <FormItem4
                                                    {...formItemLayout}
                                                    label={(
                                                        <span>
                                                            {getText('lbFamilyName')}
                                                        </span>
                                                    )}
                                                    name='family_name'
                                                    rules={[{ required: true, message: getText('msgFamilyNameRequired'), whitespace: true }]}
                                                    initialValue={user.family_name}
                                                >
                                                        <Input />
                                                </FormItem4>
                                                <FormItem4
                                                    {...formItemLayout}
                                                    label={(
                                                        <span>
                                                            {getText('lbGivenName')}
                                                        </span>
                                                    )}
                                                    name='given_name'
                                                    rules={[{ required: true, message: getText('msgGiveNameRequired'), whitespace: true }]}
                                                    initialValue={user.given_name}
                                                >
                                                        <Input />
                                                </FormItem4>
                                                <FormItem4
                                                    {...formItemLayout}
                                                    label={getText('lbAddress')}
                                                    name='co_address1'
                                                    rules={[{
                                                        required: true, message: getText('msgAddressRequired')
                                                    }]}
                                                    initialValue={user.co_address1}
                                                >
                                                        <Input />
                                                </FormItem4>
                                                <FormItem4
                                                    {...formItemLayoutWithOutLabel}
                                                    name='co_address2'
                                                    initialValue={user.co_address2}
                                                >
                                                        <Input />
                                                </FormItem4>
                                                <FormItem4
                                                    {...formItemLayoutWithOutLabel}
                                                    name='co_address3'
                                                    initialValue={user.co_address3}
                                                >
                                                        <Input />
                                                </FormItem4>
                                                <FormItem4
                                                    {...formItemLayoutWithOutLabel}
                                                    name='co_address4'
                                                    initialValue={user.co_address4}
                                                >
                                                        <Input />
                                                </FormItem4>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} type="flex" justify="start">
                                            <Col span={14}>
                                                <FormItem4
                                                    {...formItemLayout}
                                                    label={(
                                                        <span>
                                                            {getText('lbCountryCode')}
                                                        </span>
                                                    )}
                                                    validateStatus={!this.doCheckValidCode(user.co_country_code, fdcList, 'country_code') ? 'error' : undefined}
                                                    help={!this.doCheckValidCode(user.co_country_code, fdcList, 'country_code') ? getText('msgInvalidCountryCode') : undefined}
                                                    name='co_country_code'
                                                    rules={[{
                                                        validator: (rule, value, callback) => {
                                                            if (!this.doCheckValidCode(value, fdcList, 'country_code')) {
                                                                callback(getText('msgInvalidCountryCode'));
                                                            }

                                                            callback();
                                                        }
                                                    }]}
                                                    initialValue={user.co_country_code}
                                                >
                                                        <AutoComplete
                                                            dataSource={fdcOptions}
                                                            onSearch={(e) => {
                                                                this.handleCountryCodeChange(e, () => {
                                                                    this.handleCountryCodeOnSelect(e);
                                                                });
                                                            }}
                                                            onSelect={(e) => { this.handleCountryCodeOnSelect(e); }}
                                                            optionLabelProp="text"
                                                            placeholder={getText('lbCountryCode')}
                                                            dropdownMatchSelectWidth={false}
                                                        >
                                                            <Input />
                                                        </AutoComplete>
                                                </FormItem4>
                                            </Col>
                                            <Col {...routingFormColLayout.flag} style={countryFlagStyle} className="flagCol">
                                                {user.co_country_code &&
                                                    <FlagIcon code={user.co_country_code.toLowerCase()} size="lg" />
                                                }
                                            </Col>
                                        </Row>
                                        <Row gutter={16} type="flex" justify="start">
                                            <Col span={14}>
                                                <FormItem4
                                                    {...formItemLayout}
                                                    label={getText('lbPhoneNumber')}
                                                    name='phone'
                                                    rules={[{ required: true, message: getText('msgPhoneNumberRequired') }]}
                                                    initialValue={user.phone}
                                                >
                                                        <Input style={{ width: '100%' }} />
                                                </FormItem4>
                                                <FormItem4
                                                    {...formItemLayout}
                                                    label={getText('lbFax')}
                                                    name='fax'
                                                    rules={[{ required: true, message: getText('msgFaxRequired') }]}
                                                    initialValue={user.fax}
                                                >
                                                        <Input style={{ width: '100%' }} />
                                                </FormItem4>
                                                <FormItem4
                                                    {...formItemLayout}
                                                    label={(
                                                        <span>
                                                            {getText('lbCompany')}
                                                        </span>
                                                    )}
                                                    name='company'
                                                    rules={[{ required: true, message: getText('msgCompanyRequired') }]}
                                                    initialValue={user.company}
                                                >
                                                        <Input />
                                                </FormItem4>
                                                <FormItem4
                                                    {...formItemLayout}
                                                    label={(
                                                        <span>
                                                            {getText('lbDepartment')}
                                                        </span>
                                                    )}
                                                    name='department'
                                                    initialValue={user.department}
                                                >
                                                        <Input />
                                                </FormItem4>
                                                <FormItem4
                                                    {...formItemLayout}
                                                    label={(
                                                        <span>
                                                            {getText('lbPosition')}
                                                        </span>
                                                    )}
                                                    name='position'
                                                    initialValue={user.position}
                                                >
                                                        <Input />
                                                </FormItem4>

                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {
                                    current === 2 &&
                                    <div>
                                        <FormItem4
                                            {...formItemLayout}
                                            label={(
                                                <span>
                                                    {getText('lbPassword')}
                                                </span>
                                            )}
                                            hasFeedback
                                            name='password'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: getText('msgPasswordRequired')
                                                },
                                                {
                                                    validator: this.validateToNextPassword,
                                                }
                                            ]}
                                        >
                                                <Input type="password" autoComplete="new-password" />
                                        </FormItem4>
                                        <FormItem4
                                            {...formItemLayout}
                                            label={(
                                                <span>
                                                    {getText('lbConfirmPassword')}
                                                </span>
                                            )}
                                            hasFeedback
                                            dependencies={['password']}
                                            name='confirm_password'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: getText('msgConfirmPasswordRequired')
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }

                                                        return Promise.reject(new Error(getText('msgPasswordInconsistent')));
                                                    },
                                                }),
                                            ]}
                                        >
                                                <Input type="password" onBlur={this.handleConfirmBlur} />
                                        </FormItem4>
                                    </div>
                                }
                                {
                                    current === 3 &&
                                    <Result
                                        status="success"
                                        title="Activate Account Successfully"
                                        subTitle={"Please login to start the new experience. You will be able to see your shipments once they are processed in the system!"}
                                        extra={[
                                            <Button type="primary" key="back" onClick={this.goToLoginPage}>
                                                Go To Login Page
                                        </Button>
                                        ]}
                                    />
                                }
                                {/*
                                    current === 2 &&
                                    <Result
                                        status={activateResultStatus}
                                        title={activateResultTitle}
                                        subTitle={activateResultMsg}
                                        extra={[
                                            <Button type="primary" key="back" onClick={this.goToLoginPage}>
                                                Go To Login Page
                                        </Button>
                                        ]}
                                    />
                                */}
                                {
                                    (current === 0) &&
                                    <Row gutter={16} type="flex" justify="start">
                                        <Col span={14}>
                                            <FormItem4
                                                {...formItemLayoutWithOutLabel}
                                            >
                                                <span className="terms-warning">
                                                    <b>
                                                        By clicking "Sign up" button, you agree to the <a className="terms" onClick={() => this.setModalTermsOfUseVisible(true)}>Terms of Use</a>
                                                    </b>
                                                </span>

                                            </FormItem4>
                                            <FormItem4
                                                {...formItemLayoutWithOutLabel}
                                            >
                                                <Button type="primary" htmlType="submit" style={{ marginRight: '1em' }}>{getText("lbSignUp")}</Button>

                                            </FormItem4>
                                        </Col>
                                    </Row>
                                }
                                {
                                    current === 2 &&
                                    <div>
                                        <FormItem4
                                            {...formItemLayoutWithOutLabel}
                                        >
                                            <Button type="primary" htmlType="submit" style={{ marginRight: '1em' }}>{getText("lblSubmit")}</Button>

                                        </FormItem4>
                                    </div>
                                }
                            </Form4>
                        </Card>
                    </Spin>
                    <Footer className="footer"><a href="https://www.mrspedag.com/">M+R SPEDAG GROUP</a> &copy; {now.getFullYear()}</Footer>

                    <Modal
                        title={
                            <div className="terms-modal-header">
                                <h1>Terms of Use</h1>
                            </div>
                        }
                        
                        visible={this.state.modalTermsOfUseVisible}
                        onCancel={() => this.setModalTermsOfUseVisible(false)}
                        footer={null}
                    >
                        <div className="terms-modal-content">
                            <p>{getText("lbTermsOfUseContent")}</p>
                        </div>
                    </Modal>
                </Layout>
            </Layout>
        );
    }
}

export default withTracker(RegistrationWithoutSIS);
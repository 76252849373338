
import { useEffect, useRef } from 'react';
import Ajax from './../Ajax';
import SignalR from '../../components/SignalR';
import { Message } from '../../components/base';
import { DateTimeFormat2, GUID } from './../Common';
import moment from "moment";

const signalr = new SignalR('NotificationHub');

const baseUrl = `${process.env.PUBLIC_URL}/api/v1/Notification`;
const ShipmentBaseUrl = `${process.env.PUBLIC_URL}/api/v1/Shipment`;
const ConsolidationBaseUrl = `${process.env.PUBLIC_URL}/api/v1/Consolidation`;
const notificationReportApiUrl = `${process.env.REACT_APP_REPORT_WEB_API_URL}/notification`;

export const enableNotification = true;

export const subscribeUserNotification = async refreshMsg => {
    signalr.on('ReceiveNotification', msgData => refreshMsg(msgData) );
}

export const stopSignalR = async () => {
    signalr.off();
}

export const sendUserNotification = async ncData => {
    if (enableNotification) {
        try {
            const { module, sub_module, doc_code, event_code, desc_key, desc_param, href, email_subject_param, email_body_param } = ncData;
            const data = { module, sub_module, doc_code, event_code, desc_key, desc_param, href, guid: GUID(), email_subject_param, email_body_param, visible_to: "All" };

            await addNotification(data);
            signalr.send('SendNotification', data);
        } catch (errMsg) {
            console.log('error', errMsg);
        }
    }
}

export const unfollowNotification = async (module, doc_code) => {
    try {
        let result = await Ajax.post(`${notificationReportApiUrl}/unfollowNotification`, { module, doc_code });
        if (result.data !== true)
            throw "Fail to unfollow."

        return result.data;
    } catch (errMsg) {
        Message.destroy();
        Message.error(`${errMsg}`);
        console.log('error', errMsg);
    }
}

const addNotification = async ncData => {
    let result = await Ajax.post(`${notificationReportApiUrl}/addNotification`, ncData);
    if (result.data !== true)
        throw "Fail to send notification."
}

export const markAllMessagesAsRead = async () => {
    try {
        let result = await Ajax.post(`${notificationReportApiUrl}/markAllNotificationMessagesAsRead`);
        if (result.data !== true)
            throw "Fail to save."

        return result.data;
    } catch (errMsg) {
        Message.destroy();
        Message.error(`${errMsg}`);
        console.log('error', errMsg);
    }
}

export const deleteAllMessages = async () => {
    try {
        let result = await Ajax.post(`${notificationReportApiUrl}/deleteAllNotificationMessages`);
        if (result.data !== true)
            throw "Fail to delete."

        return result.data;
    } catch (errMsg) {
        Message.destroy();
        Message.error(`${errMsg}`);
        console.log('error', errMsg);
    }
}

export const upsertMessage = async message => {
    try {
        let result = await Ajax.post(`${notificationReportApiUrl}/upsertNotificationMessage`, message);
        if (result.data !== true)
            throw "Fail to save."

        return result.data;
    } catch (errMsg) {
        console.log('error', errMsg);
    }
}

export const getSettingAndConfig = async (module, doc_code) => {
    try {
        const res = await Ajax.get(`${baseUrl}/GetNotificationSetting/${module || null}/${doc_code || null}`);
        return res.data;
    } catch (e) {
        console.log('error', e);
    }
}

export const upsertConfig = async (module, doc_code, configs) => {
    try {
        if ((module || '') === '')
            throw "Invalid module";
        if (module !== 'All' && (doc_code || '') === '')
            throw "Invalid doc_code";

        let result = await Ajax.post(`${notificationReportApiUrl}/upsertNotificationConfigs`, configs);
        if (result.data !== true)
            throw "Fail to save."

        return result.data;
    } catch (errMsg) {
        Message.destroy();
        Message.error(`${errMsg}`);
        console.log('error', errMsg);
    }
}

export const getMessage = async ( page_size, page_number, read_filter, search_text) => {
    try {
        const res = await Ajax.post(`${baseUrl}/GetNotificationMessage`, { page_size, page_number, read_filter, search_text });
        return res.data;
    } catch (errMsg) {
        Message.destroy();
        Message.error(`${errMsg}`);
        console.log('error', errMsg);
    }
}

export const getSystemDefaultEvent = (module, settings) => settings.filter(s => s.module === module && s.is_default).map(s => s.key);

export const getSystemDefaultConfig = (module, settings) => {
    return {
        module,
        doc_code: "",
        follow_setting: getSystemDefaultEvent(module, settings),
        in_app_note: true,
        email_note: false,
        email_address: "",
        is_follow: false,
        customized: false
    };
}

export const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const displayErrorMsg = (errorMsg = []) => {
    if (errorMsg.length > 0) {
        return (
            <div style={{ overflowY: 'auto', maxHeight: 300 }}>
                <ul>
                    {errorMsg.map((msg, index) => <li key={index}>{msg}</li>)}
                </ul>
            </div>
        );
    }
    else return '';
}

export const getMessageDate = message_dt => {
    let result = "";
    try {
        let dt = new moment(message_dt);
        let now = new moment();
        let duration = moment.duration(now.diff(dt));
        let hours = duration.asHours();
        let mins = duration.asMinutes();
        let days = duration.asDays();
        let secs = duration.asSeconds();

        if (days >= 1) {
            result = new moment(message_dt).format(DateTimeFormat2);
        } else if (hours >= 1) {
            result = `${Math.floor(hours)} hours ago`;
        } else if (mins >= 1) {
            result = `${Math.floor(mins)} mins ago`;
        } else {
            result = `${secs < 0 ? 0 : Math.floor(secs)} seconds ago`;
        }

    } catch (e) {
        console.log(e);
    }

    return result;
}

export const getUserProfileAvailableEventCodes = async () => {
    let setting = [];
    try {
        let result = await Ajax.get(`${notificationReportApiUrl}/getUserProfileAvailableEventCodes`);
        
        if (!Array.isArray(result.data))
            throw "Fail to load user profile available event codes."

        setting = result.data;
    } catch (errMsg) {
        Message.destroy();
        Message.error(`${errMsg}`);
        console.log('error', errMsg);
    }
    return setting;
}

export const loadBookingApprovalRemarkImage = async (messages, dict) => {
    try
    {
        const requests = [];
        dict.forEach(d => {            
            requests.push(Ajax.get(`${ShipmentBaseUrl}/GetBookingApprovalRemarkImage/${d.file_name}`));
        });

        const results = await Promise.allSettled(requests);
        results.filter(rs => rs.status !== 'rejected').forEach(rs => {
            let d = dict.find(d => d.file_name === rs.value.data.file_name);
            let file_type = d.file_name.substring(d.file_name.lastIndexOf(".")+1);
            messages[d.idx].desc_param.remark = messages[d.idx].desc_param.remark.replace(`cid:${d.file_name}`, `data:image/${file_type};base64,${ rs.value.data.file_base64}`);
        });
    } catch (error)
    {
        Message.destroy();
        Message.error("Load remark image fail.");
    }
}

export const loadBookingApprovalRemarkImage2 = async (html_desc, msg, dict) => {
    try
    {
        const requests = [];
        dict.forEach(d => {            
            requests.push(Ajax.get(`${ShipmentBaseUrl}/GetConsolidationApprovalRemarkImage/${d.file_name}`));
        });

        const results = await Promise.allSettled(requests);
        results.filter(rs => rs.status !== 'rejected').forEach(rs => {
            let d = dict.find(d => d.file_name === rs.value.data.file_name);
            let file_type = d.file_name.substring(d.file_name.lastIndexOf(".")+1);
            html_desc = html_desc.replace(`cid:${d.file_name}`, `data:image/${file_type};base64,${rs.value.data.file_base64}`);
            msg.desc_param.remark = msg.desc_param.remark.replace(`cid:${d.file_name}`, `data:image/${file_type};base64,${rs.value.data.file_base64}`);
        });
    } catch (error)
    {
        Message.destroy();
        Message.error("Load remark image fail.");
    }
    return html_desc;
}

export const loadConsolidationRemarkImage = async (messages, dict) => {
    try
    {
        const requests = [];
        dict.forEach(d => {            
            requests.push(Ajax.get(`${ConsolidationBaseUrl}/GetConsolidationApprovalRemarkImage/${d.file_name}`));
        });

        const results = await Promise.allSettled(requests);
        results.filter(rs => rs.status !== 'rejected').forEach(rs => {
            let d = dict.find(d => d.file_name === rs.value.data.file_name);
            let file_type = d.file_name.substring(d.file_name.lastIndexOf(".")+1);
            messages[d.idx].desc_param.remark = messages[d.idx].desc_param.remark.replace(`cid:${d.file_name}`, `data:image/${file_type};base64,${ rs.value.data.file_base64}`);
        });
    } catch (error)
    {
        Message.destroy();
        Message.error("Load remark image fail.");
    }
}

export const loadConsolidationRemarkImage2 = async (html_desc, msg, dict) => {
    try
    {
        const requests = [];
        dict.forEach(d => {            
            requests.push(Ajax.get(`${ConsolidationBaseUrl}/GetConsolidationRemarkImage/${d.file_name}`));
        });

        const results = await Promise.allSettled(requests);
        results.filter(rs => rs.status !== 'rejected').forEach(rs => {
            let d = dict.find(d => d.file_name === rs.value.data.file_name);
            let file_type = d.file_name.substring(d.file_name.lastIndexOf(".")+1);
            html_desc = html_desc.replace(`cid:${d.file_name}`, `data:image/${file_type};base64,${rs.value.data.file_base64}`);
            msg.desc_param.remark = msg.desc_param.remark.replace(`cid:${d.file_name}`, `data:image/${file_type};base64,${rs.value.data.file_base64}`);
        });
    } catch (error)
    {
        Message.destroy();
        Message.error("Load remark image fail.");
    }
    return html_desc;
}

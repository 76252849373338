import React, { Suspense, lazy } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';

import routingTable from './routingTable';
import withAuth from './components/withAuth';
import AuthService from './components/AuthService';
import BaseLayout from './screens/BaseLayout';
import { FallbackElements } from './components/Common';
import withTracker from './components/withTracker';
import AccessDeclined from './screens/AccessDeclined';
import { enableNotification } from './components/Notification/common';

const Layout = withRouter(BaseLayout);

const routing = routingTable.map(route => {
    route.component = lazy(() => import(`./screens/${route.filePath}`));
    return route;
});

const App = () => {
    const Auth = new AuthService();

    return (
        <Layout>
            <Suspense fallback={FallbackElements}>
                <Switch>
                    {
                        routing.filter(route => !(route.path||'').includes("Notification/") || enableNotification).map((route, i) => {
                            const component = (!route.funcId || (route.funcId && Auth.isAccessable(null, route.funcId))) ?
                                route.component :
                                AccessDeclined;
                            
                            return (
                                <Route
                                    path={route.path ? `${process.env.PUBLIC_URL}${route.path}` : null}
                                    component={component}
                                    key={i}
                                    exact={route.exact}
                                />
                            );
                        })
                    }
                </Switch>
            </Suspense>
        </Layout>
    );
};

export default withTracker(withAuth(App));
import React, { Component } from 'react';
import AuthService from './AuthService';
import history from './history';
import moment from "moment";

let timeout = false;
let resetTimer;
let hasBLRenewToken = false;
let popupAlertTimeUTC = null;
//let tokenTimeoutID = null;

export default function withAuth(AuthComponent) {
    const Auth = new AuthService();
    
    return class AuthWrapped extends Component {
        state = {
            user: null
        };

        //replace UNSAFE_componentWillMount
        //UNSAFE_componentWillMount() {
        componentDidMount() {
            const { location: { pathname, search, hash } } = history;
            let currUrl = `${pathname}${search}${hash}`;
            if (!Auth.loggedIn()) {
                timeout = true;
                this.toLoginPage(currUrl);
            }
            else {
                try {
                    //console.log('componentDidMount')
                    if (!hasBLRenewToken) {
                        Auth.renewIfNeeded(resetTimer);
                        //Auth.renewIfNeeded();
                    }
                    else {
                        hasBLRenewToken = true;
                    }
                    
                    const profile = Auth.getProfile();
                    profile.userProfiles = Auth.getUserProfiles();
                    profile.userPreferences = Auth.getUserPreferences();
                    this.setState({
                        user: profile
                    });
                }
                catch (err) {
                    timeout = true;
                    Auth.logout();
                    this.toLoginPage(currUrl);
                }
            }
        }

        //notify = () => {
        //    if (!("Notification" in window)) {
        //        console.log("This browser does not support desktop notification");
        //    }
        //    else {
        //        console.log('support')
        //        if (Notification.permission === "granted") {
        //            // If it's okay let's create a notification
        //            var notification = new Notification("Hi there!");
        //            console.log('granted')
        //        }

        //        // Otherwise, we need to ask the user for permission
        //        else if (Notification.permission !== "denied") {
        //            Notification.requestPermission().then(function (permission) {
        //                console.log('denied')
        //                // If the user accepts, let's create a notification
        //                if (permission === "granted") {
        //                    var notification = new Notification("Hi there!");
        //                    console.log('denied granted')
        //                }
        //            });
        //        }
        //    }
        //}

        componentDidUpdate() {
            const { location: { pathname, search, hash } } = history;
            let currUrl = `${pathname}${search}${hash}`;
            let loginUrl = `${process.env.PUBLIC_URL}/login`;

            //setInterval(this.notify, 5000);

            if (!Auth.loggedIn()) {
                if (!currUrl.includes(loginUrl)) {
                    timeout = true;
                    this.toLoginPage(currUrl);
                }
            }
            else {
                try {
                    //console.log('withAuth shouldComponentUpdate ', (this.state !== nextState || this.props !== nextProps))
                    //Auth.renewIfNeeded(() => { console.log('with auth shouldComponentUpdate'); resetTimer && resetTimer() });
                    //Auth.renewIfNeeded();
                    let nowUTC = moment.utc();
                    
                    if (!moment(popupAlertTimeUTC).isValid()) {
                        popupAlertTimeUTC = nowUTC;
                        Auth.prompAlertMsg();
                    }
                    else if (nowUTC.diff(popupAlertTimeUTC, 'seconds') >= 5) {
                        popupAlertTimeUTC = nowUTC;
                        Auth.prompAlertMsg();
                    }

                    if (!hasBLRenewToken) {
                        Auth.renewIfNeeded(resetTimer);
                    }
                    else {
                        hasBLRenewToken = true;
                    }
                }
                catch (err) {
                    timeout = true;
                    Auth.logout();
                    this.toLoginPage(currUrl);
                }
            }
        }

        toLoginPage = (currUrl) => {
            let loginUrl = `${process.env.PUBLIC_URL}/login`;
            if (currUrl) loginUrl += `?redir=${encodeURIComponent(currUrl)}`;
            history.replace(loginUrl);
        }

        render() {
            if (this.state.user) {
                return (
                    <AuthComponent user={this.state.user} {...this.props} />
                );
            }
            else {
                return null;
            }
        }
    };
}

export const isTimeout = () => {
    return timeout;
}

export function setIsTimeout(isTimeout) {
    timeout = isTimeout;
}

export function setResetTimer(resetTimerFunc) {
    resetTimer = resetTimerFunc;
}

export function getResetTimer() {
    return resetTimer && resetTimer();
}

export function setHasBLRenewToken(renewToken) {
    hasBLRenewToken = renewToken;
}
import axios from 'axios';
import history from './history';
import { processLogout } from './AuthService';

/**
 * @component
 * Wrapper of `axios`, when receiving 401 response, will redirect to login page
 */

const errorHandling = (error, showError) => {
    if (!axios.isCancel(error) && showError) {
        if (error.response && error.response.status === 401) {
            processLogout();
            history.replace('/login');
        }
        else if (error.response && error.response.status === 403) {
            history.replace('/AccessDeclined');
        }
        else {
            if (!error.response || error.response.status !== 404) {
                console.error(error, error.response);
            }
        }
    }
    return Promise.reject(error);
};

class Ajax {
    defaults = axios.defaults;
    CancelToken = axios.CancelToken;

    get(url, responseType = 'json', cancelToken = null, headers = null, showError = true) {
        let config = { responseType: responseType, cancelToken: cancelToken };
        if (headers !== null && headers !== undefined) config.headers = headers;

        return axios.get(url, config)
            .then(res => {
                return Promise.resolve(res);
            })
            .catch(error => {
                return errorHandling(error, showError);
            });
    }

    post(url, data, responseType = 'json', cancelToken = null) {
        return axios.post(url, data, { responseType: responseType, cancelToken: cancelToken })
            .then(res => {
                return Promise.resolve(res);
            })
            .catch(error => {
                return errorHandling(error);
            });
    }

    put(url, data, responseType = 'json') {
        return axios.put(url, data, { responseType: responseType })
            .then(res => {
                return Promise.resolve(res);
            })
            .catch(error => {
                return errorHandling(error);
            });
    }

    delete(url) {
        return axios.delete(url)
            .then(res => {
                return Promise.resolve(res);
            })
            .catch(error => {
                return errorHandling(error);
            });
    }

    all(promises) {
        return axios.all(promises);
    }

    spread(callbacks) {
        return axios.spread(callbacks);
    }

    isCancel = (value) => axios.isCancel(value)
}

export default new Ajax();
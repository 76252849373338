import React, { PureComponent, useState } from 'react';
import { Table } from 'antd';
import { Resizable } from 'react-resizable';
import ReactDragListView from 'react-drag-listview';
import { Popover } from '.';
import deepEqual from 'lodash.isequal';

import './Table.scss';
import { isArray } from 'util';

const calcScroll = (columns, defaultColWidth, maxHeight, scroll) => {
    if (!columns || columns.length === 0) return undefined;
    let x = columns.map(c => c.width || defaultColWidth).reduce((a, c) => a + c);

    return Object.assign({ x, y: maxHeight }, scroll);
};

const getRowClassName = (rowClassName) => (
    (record, index) => {
        let className = index % 2 === 0 ? '' : 'altRow';
        if (rowClassName && typeof rowClassName === 'function') {
            className = ` ${rowClassName(record, index)}`;
        }
        return className;
    }
);

const beforeValueStyle = {
    color: 'red',
    textDecoration: 'line-through'
};

const afterValueWarningStyle = {
    color: 'orange'
};

const afterValueErrorStyle = {
    color: 'red'
};

export const pageSizeOptions = ['10', '20', '30', '40', '50', '100'];

class ListTable extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            columns: this.props.columns
        };
    }
    

    componentDidUpdate(prevProps) {
        //20191218 ms: to improve performance of rendering table
        //this.setState({ columns: this.props.columns });
        const { columns } = this.props;
        const { columns: prev_columns } = prevProps;

        if (!isArray(this.state.columns) || this.state.columns.length !== this.props.columns.length) {
            this.setState({ columns: this.props.columns });
            return;
        }

        for (let i = 0; i < this.state.columns.length; ++i) {
            if (this.state.columns[i].key !== this.props.columns[i].key) {
                this.setState({ columns: this.props.columns });
                return;
            }
        }

        if (!deepEqual(columns, prev_columns)) {
            this.setState({ columns: this.props.columns });
        }
    }

    componentDidMount() {
        this.setState({ columns: this.props.columns });
    }

    DragableBodyRow = props => {
        const { dragRowStartCallBack } = this.props;
        if (!dragRowStartCallBack) {
            return <tr {...props} />;
        }

        return (
            <tr
                draggable="true"
                onDragStart={dragRowStartCallBack(props['data-row-key'])}
                {...props}
            />
        );
    }

    defaultColWidth = 100;
    size = "middle";
    maxHeight = 450;

    ResizeableTitle = props => {
        const { onResize, onClick, width, ...restProps } = props;
        const [allowClick, setAllowClick] = useState(true);
        if (!width) {
            return <th {...restProps} />;
        }

        return (
            <Resizable
                width={width}
                height={0}
                onResize={onResize}
                onResizeStart={e => setAllowClick(false)}
                onResizeStop={e => {
                    this.props.resizeColCallback && this.props.resizeColCallback(this.state.columns);
                    setTimeout(setAllowClick, 300, true);
                }}
                onClick={e => allowClick && onClick && onClick(e)}
            >
                <th {...restProps} />
            </Resizable>
        );
    };

    components = {
        header: {
            cell: this.ResizeableTitle,
        },
        body: {
            row: this.DragableBodyRow,
        },
    };


    handleResize = index => (e, { size }) => {
        this.setState(({ columns }) => {
            const nextColumns = [...columns];
            nextColumns[index] = {
                ...nextColumns[index],
                width: size.width,
            };

            return { columns: nextColumns };
        });
    };

    onDragEnd = callback => (fromIndex, toIndex) => {
        this.setState(({ columns }) => {
            const { rowSelection } = this.props;
            const nextColumns = [...columns];

            if (rowSelection) {
                fromIndex = fromIndex - 1;
                toIndex = toIndex - 1;
            }

            const item = nextColumns.splice(fromIndex, 1)[0];
            nextColumns.splice(toIndex, 0, item);
            
            callback && callback(nextColumns)
            return { columns: nextColumns };
        });
    };

    _checkError = (checkError, col, rowKey, customFunction, text, record, rowIndex) => {
        if (checkError) {
            let errors = [];
            if (Array.isArray(col.dataIndex)) {
                col.dataIndex.forEach((data) => {
                    errors.push(checkError.Func(record[rowKey], data));
                });
            }
            else {
                errors.push(checkError.Func(record[rowKey], col.dataIndex));
            }
            if (errors && errors.some(e => e && (e.target))) {
                return (
                    <Popover
                        title={checkError.ErrorTitle || ''}
                        content={<>
                            <div style={afterValueErrorStyle}>{
                                (checkError.ErrorFieldSourceName || '') + ': ' +
                                (customFunction ? customFunction(text, record, rowIndex, errors.map(e => e.source).join(""), false) :
                                    errors.map(e => e.source).join(""))
                            }</div>
                            <div style={afterValueErrorStyle}>{
                                (checkError.ErrorFieldTargetName || '') + ': ' +
                                (customFunction ? customFunction(text, record, rowIndex, errors.map(e => e.target).join(""), false) :
                                    errors.map(e => e.target).join(""))
                            }</div>
                        </>}
                        trigger="hover"
                    >
                        <div style={afterValueErrorStyle}>{
                            customFunction ? customFunction(text, record, rowIndex, errors.map(e => e.target).join(""), true) :
                                errors.map(e => e.source).join("")
                        }</div>
                    </Popover>
                )
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }

    _checkAmended = (checkAmended, col, rowKey, customFunction, text, record, rowIndex) => {
        if (checkAmended) {
            let changes = [];
            if (Array.isArray(col.dataIndex)) {
                col.dataIndex.forEach((data) => {
                    changes.push(checkAmended.Func(record[rowKey], data));
                });
            }
            else {
                changes.push(checkAmended.Func(record[rowKey], col.dataIndex));
            }
            if (changes.length > 0 && changes.some(c => c && c.every(m => m && m.afterValue))) {
                let beforeValues = changes.map(c => c.map(m => m ? m.beforeValue : null).toString());
                let afterValues = changes.map(c => c.map(m => m ? m.afterValue : null).toString());
                if (Array.isArray(col.dataIndex)) {
                    changes.forEach((data,i) => {
                        if (data[0] === undefined) {
                            beforeValues[i] = record[col.dataIndex[i]];
                            afterValues[i] = record[col.dataIndex[i]];
                        }
                    })
                }

                return (
                    <Popover
                        title={checkAmended.WarningTitle || ''}
                        content={<>
                            <div style={beforeValueStyle}>{customFunction ? customFunction(text, record, rowIndex, beforeValues, false) : beforeValues.join("")}</div>
                            <div style={afterValueWarningStyle}>{customFunction ? customFunction(text, record, rowIndex, afterValues, false) : afterValues.join("")}</div>
                        </>}
                        trigger="hover"
                    >
                        <div style={afterValueWarningStyle}>{customFunction ? customFunction(text, record, rowIndex, afterValues, true) : afterValues.join("")}</div>
                    </Popover>
                )
            }
            else {
                return null;
            }
        } else {
            return null;
        }
    }


    render() {
        const { scroll, dataSource, pagination, isLoading, onChange, rowKey, size, rowClassName, error, rowSelection, className, dragColEndCallback, checkAmended, checkError, ...res } = this.props;
        //this.tempColumns = [...this.state.columns];
        const paginationWithDefaults = typeof pagination === 'boolean' ? pagination : Object.assign({ pageSizeOptions: pageSizeOptions, position: 'bottom' }, pagination);
        const getColumns = (columns) => {
            return columns.map((col, index) => {
                const customFunction = col.customRender;
                return ({
                    ...col,
                    render: col.render || (
                        (text, record, rowIndex) => {
                            let errorResult = this._checkError(checkError, col, rowKey, customFunction, text, record, rowIndex);
                            if (errorResult) {
                                return errorResult;
                            }
                            else {
                                let amendedResult = this._checkAmended(checkAmended, col, rowKey, customFunction, text, record, rowIndex);
                                if (amendedResult) {
                                    return amendedResult;
                                }
                                else {
                                    if (customFunction) {
                                        return customFunction(text, record, rowIndex, null, false);
                                    }
                                    return (<div>{text}</div>);
                                }
                            }
                        }
                    ),
                    onHeaderCell: column => ({
                        width: column.width,
                        onResize: this.handleResize(index)
                    })
                })
            });
        };

        let errMsg = "";
        let msgType = "info";

        if (error) {
            errMsg = error;
            msgType = "danger";
        }

        if (errMsg && errMsg.length > 0 && !isLoading) {
            return (
                <div className={`alert alert-${msgType}`} role="alert">
                    {errMsg}
                </div>
            );
        }

        return (
            <ReactDragListView.DragColumn
                onDragEnd={this.onDragEnd(dragColEndCallback)}
                nodeSelector={"th"}
                handleSelector={".ant-table-column-sorters"}
                ignoreSelector={"th.react-resizable-handle"}
            >
                <Table
                    {...res}
                    columns={getColumns(this.state.columns)}
                    dataSource={dataSource}
                    components={this.components}
                    rowKey={rowKey}
                    scroll={calcScroll(getColumns(this.state.columns), this.defaultColWidth, dataSource && dataSource.length > 0 ? this.maxHeight : undefined, scroll)}
                    pagination={paginationWithDefaults}
                    loading={isLoading}
                    onChange={onChange}
                    size={size}
                    rowClassName={getRowClassName(rowClassName)}
                    rowSelection={rowSelection}
                    className={className}
                />
            </ReactDragListView.DragColumn>
        );
    }
};

//const ListTable = ({ columns, dataSource, error, pagination, onChange, isLoading, defaultColWidth, rowKey, size, rowClassName, maxHeight, scroll, resizeColCallback, rowSelection, className, ...props }) => {
//    console.log('ListTable')
//    if (columns.length > 0) {
//        const ResizeableTitle = (props) => {
//            const { onResize, onClick, width, ...restProps } = props;
//            const [allowClick, setAllowClick] = useState(true);
//            if (!width) {
//                return <th {...restProps} />;
//            }

//            return (
//                <Resizable
//                    width={width}
//                    height={0}
//                    onResize={onResize}


//                    onMouseDown={e => {
//                        setAllowClick(true);
//                    }}
//                    onResizeStart={e => {
//                        setAllowClick(false);
//                    }}
//                    onResizeStop={e => {
//                        resizeColCallback && resizeColCallback(columns);
//                    }}
//                    onClick={e => allowClick && onClick(e)}

//                >
//                    <th {...restProps} />
//                </Resizable>
//            );
//        };

//        let resizeCol = columns;

//        const setColumns = (col) => {
//            resizeCol = col();
//        }

//        const getResizeCol = () => {
//            return resizeCol;
//        }

//        const components = {
//            header: {
//                cell: ResizeableTitle
//            }
//        };

//        const handleResize = (index, columns, callback) => {
//            return (e, { size }) => {
//                //to do

//                //callback && callback(nextColumns);
//                //this.setState(({ columns }) => {
//                setColumns(() => {
//                    const nextColumns = [...columns];
//                    nextColumns[index] = {
//                        ...nextColumns[index],
//                        width: size.width,
//                    };
//                    console.log('handleResize setColumns')
//                    return nextColumns;
//                })
//                //callback && callback(() => {
//                //    const nextColumns = [...columns];
//                //    nextColumns[index] = {
//                //        ...nextColumns[index],
//                //        width: size.width,
//                //    };

//                //    //
//                //    return nextColumns;
//                //});
//                console.log('handleResize')
//                callback && callback(getResizeCol());
//            }
//            //col(e, { size });
//            //return callback && callback(getResizeCol());
//            //});
//        }

//        const getColumns = (columns) => {
//            console.log('getColumns')
//            return columns.map((col, index) => ({
//                ...col,
//                onHeaderCell: column => ({
//                    width: column.width,
//                    onResize: handleResize(index, columns, resizeColCallback),
//                    //onMouseUp: () => {
//                    //    onMouseUp();
//                    //},
//                })
//            }));
//        };

//        const paginationWithDefaults = typeof pagination === 'boolean' ? pagination : Object.assign({ pageSizeOptions: pageSizeOptions }, pagination);

//        let errMsg = "";
//        let msgType = "info";

//        if (error) {
//            errMsg = error;
//            msgType = "danger";
//        }

//        if (errMsg && errMsg.length > 0 && !isLoading) {
//            return (
//                <div className={`alert alert-${msgType}`} role="alert">
//                    {errMsg}
//                </div>
//            );
//        }

//        return (
//            <Table
//                columns={getColumns(columns, resizeColCallback)}
//                dataSource={dataSource}
//                components={components}
//                rowKey={rowKey}
//                //scroll={calcScroll(columns, defaultColWidth, maxHeight, scroll)}
//                scroll={calcScroll(columns, defaultColWidth, dataSource && dataSource.length > 0 ? maxHeight : undefined, scroll)}
//                pagination={paginationWithDefaults}
//                loading={isLoading}
//                onChange={onChange}
//                size={size}
//                rowClassName={getRowClassName(rowClassName)}
//                rowSelection={rowSelection}
//                className={className}
//                {...props}
//                //bordered
//            />
//        );
//    }
//    return null;
//};

//ListTable.defaultProps = {
//    defaultColWidth: 100,
//    size: "middle",
//    maxHeight: 450
//};

export default ListTable;

import React, { useState, Component } from 'react';

import withAuth from '../../components/withAuth';

import { getText, multiLine } from '../../i18n/i18next';

import { Row, Col, Skeleton, Badge, Tooltip, Card, Divider, Dropdown, Menu, Empty, Message, Notification, Pagination, Modal, Switch, Button } from './../base';
import history from './../history';

import { subscribeUserNotification, stopSignalR, upsertMessage, markAllMessagesAsRead, deleteAllMessages, getMessage, unfollowNotification, getMessageDate, getUserProfileAvailableEventCodes, loadBookingApprovalRemarkImage, loadBookingApprovalRemarkImage2, loadConsolidationRemarkImage, loadConsolidationRemarkImage2 } from './common';

import SearchBoxWithAutoComplete from './../SearchBoxWithAutoComplete';

import AuthService from './../AuthService';

import './NotificationBellButtonGroup.scss';
import { BellOutlined, BellFilled, CheckOutlined, DeleteOutlined, SyncOutlined, SettingOutlined, FullscreenOutlined, DoubleRightOutlined, DoubleLeftOutlined, SearchOutlined, MoreOutlined, LeftOutlined, DisconnectOutlined, FileOutlined, BookOutlined, CheckCircleOutlined, ClusterOutlined, FilePptOutlined, ReconciliationOutlined } from '@ant-design/icons';

import 'braft-editor/dist/output.css'

//const masterListUrl = `${process.env.PUBLIC_URL}/BMS/Notification/masterList`;
const confirm = Modal.confirm;

const Auth = new AuthService();

const checkClickOutsideNotificationList = setNotificationListVisible => {
    const [clientAdded , setClientAdded] = useState(false);

    if (!clientAdded) {
        setClientAdded(true);
        
        document.addEventListener("mousedown", (evt) => {
            let targetElement = evt.target; // mousedowned element
            let isClickInsideElement = false;
        
            do {
                isClickInsideElement = targetElement && typeof targetElement.className === 'string' && (targetElement.className || '').includes("notificationInsideElement");

                // Go up the DOM
                targetElement = targetElement.parentNode;
            } while (targetElement && !isClickInsideElement);
        
            //console.log(isClickInsideElement ? 'in' : 'out')

            if (!isClickInsideElement) {
                //Do something click is outside specified element
                setNotificationListVisible(false);
            }
        });
    }
}

const BellButton = props => {
    const { notificationListVisible, setNotificationListVisible, countUnread } = props;

    const handleOnClickBell = () => {
        setNotificationListVisible(!notificationListVisible);
    }

    return (
        <Badge count={countUnread} className="notificationInsideElement bellBadge">
            <Tooltip title={getText('notification.bellButtonToolTip')}>
                {
                    notificationListVisible ?
                        <BellFilled
                            style={{ color: "#1963ae" }}
                            onClick={handleOnClickBell}
                        />
                        :
                        <BellOutlined
                            onClick={handleOnClickBell}
                        />
                }
            </Tooltip>
        </Badge>
    );
}

export const NotificationList = props => {
    const { notificationListVisible, messages, countUnread, loading, readFilter, searchText, pagination } = props;
    const { refreshUserNotification, setNotificationListVisible, setMessages, setCountUnread, setReadFilter, setSearchText, setPagination, updateReadStatus } = props;

    checkClickOutsideNotificationList(setNotificationListVisible);

    const [searchBoxVisible, setSearchBoxVisible] = useState(false);
    const [isExpandWidth, setIsExpandWidth] = useState(false);
    
    const markAllAsRead = async () => {
        let isUpdateSuccess = await markAllMessagesAsRead();
        if (isUpdateSuccess) {
            let newMessages = messages.map(i => ({ ...i, is_read: true }));
            setMessages(newMessages);
            setCountUnread(0);
        }
    }

    const onSearchText = searchText => {
        setSearchText(searchText);
        refreshUserNotification(readFilter, searchText, pagination.pageSize, 1);
    }

    /*const handleOnShowSizeChange = (current, pageSize) => {
        let newPagination = { ...pagination };
        newPagination.current = current;
        newPagination.pageSize = pageSize;
        setPagination(newPagination);
        refreshUserNotification(readFilter, searchText, pageSize, current);
        console.log(current, pageSize)
    }*/
    
    const handleOnPageChange = (page, pageSize) => {
        let newPagination = { ...pagination };
        newPagination.current = page;
        newPagination.pageSize = pageSize;
        setPagination(newPagination);
        refreshUserNotification(readFilter, searchText, pageSize, page);
    };

    const handleSwitchreadFilter = newreadFilter => {
        let newPagination = { ...pagination };
        newPagination.current = 1;
        setPagination(newPagination);
        setReadFilter(newreadFilter);
        refreshUserNotification(newreadFilter, searchText, newPagination.pageSize, 1);
    }

    const handleMenuClick = e => {
        switch (e.key) {
            case "markAllAsRead":
                console.log('click', e.item.props.children);
                markAllAsRead();
                break;
            case "deleteAll":
                console.log('click', e.item.props.children);
                confirm({
                    className: 'notificationInsideElement',
                    title: getText('notification.msgSureToDeleteAll'),
                    centered: true,
                    cancelText: getText('lblNo'),
                    okText: getText('lblYes'),
                    onOk: handleDeleteAllMessages,
                    maskClosable: true
                });
                break;
            //case "masterList":
                //console.log('click', e.item.props.children);
                //history.push(`${process.env.PUBLIC_URL}/BMS/Notification/masterList`);
                //setNotificationListVisible(false);
                //break;
            case "refreseh":
                console.log('click', e.item.props.children);
                onSearchText(searchText);
                break;
            case "userDefaultSetting":
                console.log('click', e.item.props.children);
                history.push(`${process.env.PUBLIC_URL}/BMS/Notification/masterSetting`);
                setNotificationListVisible(false);
                break;
            default:
                console.log('click');
        }
    }

    const handleOnClickSearch = () => setSearchBoxVisible(true);

    const handleOnClickBack = () => { 
        setSearchBoxVisible(false);
        if (searchText !== '') {
            onSearchText("");
        }
    }
    
    const handleOnClickExpandWidth = () => setIsExpandWidth(!isExpandWidth);

    const handleDeleteAllMessages = async () => {
        let isUpdateSuccess = await deleteAllMessages();
        if (isUpdateSuccess) {
            setMessages([]);
            setCountUnread(0);
        }

        let newPagination = { ...pagination };
        newPagination.current = 1;
        newPagination.total = 0;
        setPagination(newPagination);
    };

    const menu = (
        <Menu onClick={handleMenuClick} className='notificationInsideElement'>
            <Menu.Item key="markAllAsRead">
                <CheckOutlined />&nbsp;
                {getText('notification.lblNotificationListMenuOption1')}
            </Menu.Item>
            <Menu.Item key="deleteAll">
                <DeleteOutlined />&nbsp;
                {getText('notification.lblNotificationListMenuOption2')}
            </Menu.Item>
            <Menu.Item key="refreseh">
                <SyncOutlined />&nbsp;
                {getText('notification.lblNotificationListMenuOption3')}
            </Menu.Item>
            {/*
                <Menu.Item key="userDefaultSetting">
                    <SettingOutlined />&nbsp;
                    {getText('notification.lblNotificationListMenuOption4')}
                </Menu.Item>
            */}
            <Menu.Item key="userDefaultSetting">
                <FullscreenOutlined />&nbsp;
                {getText('notification.lblNotificationListMenuOption4')}
            </Menu.Item>
        </Menu>
    );
    
    return (
        <Card className={`${isExpandWidth ? "expandWidth" : "normalWidth"} notificationInsideElement notificationListPopUp`} style={notificationListVisible ? {} : { display: 'none' }}>
            <Row className={"notificationInsideElement"} type="flex" style={{ overflowX: 'hidden' }}>
                <h2 className="slideIn" data-title-visible={!searchBoxVisible}>
                    {getText('notification.lblNotificationListTitle')}
                    <Switch 
                        style={{ marginLeft: '5px' }}
                        checkedChildren={getText("notification.lblNotificationListReadAll")} 
                        unCheckedChildren={getText("notification.lblNotificationListUnread")} 
                        onChange={checked => handleSwitchreadFilter(checked ? 'All' : 'Unread')}
                        checked={readFilter === 'All'} 
                    />
                    <div style={{ marginLeft: 'auto' }}>
                        {
                            isExpandWidth ?
                                <DoubleRightOutlined onClick={handleOnClickExpandWidth} />
                                :
                                <DoubleLeftOutlined onClick={handleOnClickExpandWidth} />
                        }
                        <SearchOutlined onClick={handleOnClickSearch} />
                        <Dropdown overlay={menu} trigger={['click']} align={{ offset: ['-85%', 0] }} >
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <MoreOutlined style={{ transform: 'rotate(90deg)' }}/>
                            </a>
                        </Dropdown>
                    </div>
                </h2>
                <h2 className="slideIn" data-searchbox-visible={searchBoxVisible}>
                    <LeftOutlined onClick={handleOnClickBack} />
                    <Tooltip
                        placement="topLeft"
                        title={multiLine(getText('notification.smartSearchTooltip'))}
                        overlayClassName="searchBox"
                    >
                        <div style={{ width: 'calc(100% - 46px)' }}>
                            <SearchBoxWithAutoComplete
                                name="searchNotificationText"
                                value={searchText}
                                allowClear={false}
                                optionClassName={"notificationInsideElement"}
                                style={{ flex: 1, textAlign: 'left' }}
                                onClick={() => onSearchText(searchText)}
                                onChange={setSearchText}
                                onSelect={onSearchText}
                                onBlur={onSearchText}
                            />
                        </div>
                    </Tooltip>
                </h2>
            </Row>
            <Row className="notificationListUnReadFilter">
                <Col>
                    <Button 
                        type="text" 
                        data-read-filter-selected={readFilter === 'All'} 
                        onClick={() => handleSwitchreadFilter('All')}
                    >
                        {getText("notification.lblNotificationListReadAll")}
                    </Button>
                </Col>
                <Col>
                    <Button 
                        type="text" 
                        data-read-filter-selected={readFilter === 'Unread'} 
                        onClick={() => handleSwitchreadFilter('Unread')}
                    >
                        {getText("notification.lblNotificationListUnread")}
                    </Button>
                </Col>
            </Row>
            <Row className="notificationList">
                <Skeleton className={"notificationInsideElement"} loading={loading}>
                {
                    messages.map((msg, idx) => 
                        <div key={idx}> 
                            <Divider style={{ margin: '3px 0' }} /> 
                            {React.createElement(notificationListItem, { ...msg, messages, countUnread, readFilter, pagination, refreshUserNotification, updateReadStatus })} 
                        </div>
                    )
                }
                {
                    messages.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
                </Skeleton>
            </Row>
            <Pagination style={{ textAlign: 'center', marginTop: 16 }} current={pagination.current} total={pagination.total} showSizeChanger={pagination.showSizeChanger} onChange={handleOnPageChange} />
        </Card>
    );
}

const notificationListItem = props => {

    const { messages, readFilter, pagination } = props;
    const { refreshUserNotification, updateReadStatus } = props;
    const { message_id, module, sub_module, doc_code, desc_key, desc_param, href, is_read, message_dt } = props;
    const [ showMore, setShowMore ] = useState(false);

    const removeMessage = async () => {
        let message = { ...messages.find(msg => msg.message_id === message_id), removed: true };
        let isUpdateSuccess = await upsertMessage(message);
        if (isUpdateSuccess) {
            let current = Math.ceil((pagination.total-1)/pagination.pageSize) >= pagination.current ? pagination.current : (pagination.current-1 > 0 ? pagination.current-1 : 1);
            refreshUserNotification(readFilter, "", pagination.pageSize, current);
        }
    }

    const unfollow = async () => {
        let isUpdateSuccess = await unfollowNotification(module, doc_code);
        if (isUpdateSuccess) {
            Message.success(getText('notification.msgUnfollowSuccess'));
        } else {
            Message.error(getText('notification.msgUnfollowFail'));
        }
    }

    const getModuleIcon = () => {
        if (module === 'invoice') {
            return <FileOutlined />;
        } else if (module === 'booking' && sub_module === 'booking') {
            return <BookOutlined />;
        } else if (module === 'booking' && sub_module === 'booking approval') {
            return <CheckCircleOutlined />;
        } else if (module === 'consolidation') {
            return <ClusterOutlined />;
        } else if (module === 'prs') {
            return <FilePptOutlined />;
        } else if (module === 'sopms') {
            return <ReconciliationOutlined />;
        }
    }

    const handleOnClickNotificationList = e => {
        history.push(`${process.env.PUBLIC_URL}${href}`);
        updateReadStatus(true, message_id);
        //setNotificationListVisible(false);
    }

    const handleMenuClick = e => {
        switch (e.key) {
            case "1":
                console.log('click', e.item.props.children);
                updateReadStatus(!is_read, message_id);
                break;
            case "2":
                console.log('click', e.item.props.children);
                removeMessage();
                break;
            case "3":
                console.log('click', e.item.props.children);
                unfollow();
                break;
            default:
                console.log('click');
        }
    }
    
    const handleOnClickRead = () => updateReadStatus(!is_read, message_id);

    const menu = (
        <Menu onClick={handleMenuClick} className='notificationInsideElement'>
            <Menu.Item key="1">
                <CheckOutlined />&nbsp;
                {!is_read && getText('notification.lblNotificationListItemMenuOption1a')}
                {is_read && getText('notification.lblNotificationListItemMenuOption1b')}
            </Menu.Item>
            <Menu.Item key="2">
                <DeleteOutlined />&nbsp;
                {getText('notification.lblNotificationListItemMenuOption2')}
            </Menu.Item>
            <Menu.Item key="3">
                <DisconnectOutlined />&nbsp;
                {module === "booking" && `${getText('notification.lblNotificationListItemMenuOption3a')}${doc_code}`}
            </Menu.Item>
        </Menu>
    );
    
    let html_desc = "";
    if (["notification.msgBookingNewOperationRemark", "notification.msgBookingNewPricingRemark",
        "notification.msgConsolidationNewOperationRemark", "notification.msgConsolidationNewPricingRemark"
    ].includes(desc_key))
    {
        html_desc = `<div>${getText(desc_key)}</div>`;
        for (const [key, value] of Object.entries(desc_param)) 
        {
            html_desc = html_desc.replace(`{${key}}`, value);
        }
    }

    return (
        <div className="notificationListItem">
            <Row className="readBar">
                <div className={`readIndicator ${is_read ? 'read' : 'unread'}`} onClick={handleOnClickRead}></div>
            </Row>
            <Row className="moduleIcon">
                {getModuleIcon()}
            </Row>
            <Row onClick={handleOnClickNotificationList} className="context">
                <Col data-unread-msg={!is_read}>
                    {["notification.msgBookingNewOperationRemark", "notification.msgBookingNewPricingRemark",
                        "notification.msgConsolidationNewOperationRemark", "notification.msgConsolidationNewPricingRemark"
                    ].includes(desc_key) ?
                        <div className="braft-output-content" style={{overflowX: "auto"}} dangerouslySetInnerHTML={{__html: html_desc}}></div>
                        : multiLine(getText(desc_key, desc_param))
                    }
                    {
                        showMore === true &&
                        <>
                            <br/>
                            {
                                desc_key === "notification.msgBookingUpdateSailingA2" && Array.isArray(desc_param.updated_sailing_detail) && 
                                desc_param.updated_sailing_detail.map( d => 
                                    <>{multiLine(getText("notification.msgBookingUpdateSailingA2Detail", d))}</>
                                )
                            }
                            {
                                desc_key === "notification.msgBookingUpdateRouting" && Array.isArray(desc_param.updated_routing_detail) && 
                                desc_param.updated_routing_detail.map( d => 
                                    <>{multiLine(getText("notification.msgBookingUpdateRoutingDetail", d))}</>
                                )
                            }
                            {
                                desc_key === "notification.msgConsolidationUpdateSailingA2" && Array.isArray(desc_param.updated_sailing_detail) && 
                                desc_param.updated_sailing_detail.map( d => 
                                    <>{multiLine(getText("notification.msgConsolidationUpdateSailingA2Detail", d))}</>
                                )
                            }
                            {
                                desc_key === "notification.msgBookingUpdateTotalRate" && (desc_param.contract_no??'') !== '' && 
                                <>{multiLine(desc_param.contract_no)}</>
                            }
                            {
                                desc_key === "notification.msgBookingUpdateTotalRate" && Array.isArray(desc_param.updated_total_rate_detail) && 
                                desc_param.updated_total_rate_detail.map( d => 
                                    <>{multiLine(getText("notification.msgBookingUpdateTotalRateDetail", d))}</>
                                )
                            }
                            {
                                desc_key === "notification.msgConsolidationUpdateTotalRate" && (desc_param.contract_no??'') !== '' && 
                                <>{multiLine(desc_param.contract_no)}</>
                            }
                            {
                                desc_key === "notification.msgConsolidationUpdateTotalRate" && Array.isArray(desc_param.updated_total_rate_detail) && 
                                desc_param.updated_total_rate_detail.map( d => 
                                    <>{multiLine(getText("notification.msgConsolidationUpdateTotalRateDetail", d))}</>
                                )
                            }
                            {
                                (
                                    (desc_key === "notification.msgBookingUpdateSailingA2" && Array.isArray(desc_param.updated_sailing_detail)) ||
                                    (desc_key === "notification.msgBookingUpdateRouting" && Array.isArray(desc_param.updated_routing_detail)) ||
                                    (desc_key === "notification.msgConsolidationUpdateSailingA2" && Array.isArray(desc_param.updated_sailing_detail)) ||
                                    (desc_key === "notification.msgBookingUpdateTotalRate" && ((desc_param.contract_no??'') !== '' || Array.isArray(desc_param.updated_total_rate_detail))) ||
                                    (desc_key === "notification.msgConsolidationUpdateTotalRate" && ((desc_param.contract_no??'') !== '' || Array.isArray(desc_param.updated_total_rate_detail)))
                                ) &&
                                <a className="notificationInsideElement" onClick={e => { e.stopPropagation(); setShowMore(false); }}>Hide</a>
                            }
                        </>
                    }
                    {
                        showMore !== true &&
                        (
                            (desc_key === "notification.msgBookingUpdateSailingA2" && Array.isArray(desc_param.updated_sailing_detail)) ||
                            (desc_key === "notification.msgBookingUpdateRouting" && Array.isArray(desc_param.updated_routing_detail)) ||
                            (desc_key === "notification.msgConsolidationUpdateSailingA2" && Array.isArray(desc_param.updated_sailing_detail)) ||
                            (desc_key === "notification.msgBookingUpdateTotalRate" && ((desc_param.contract_no??'') !== '' || Array.isArray(desc_param.updated_total_rate_detail))) ||
                            (desc_key === "notification.msgConsolidationUpdateTotalRate" && ((desc_param.contract_no??'') !== '' || Array.isArray(desc_param.updated_total_rate_detail)))
                        ) &&
                        <a className="notificationInsideElement" onClick={e => { e.stopPropagation(); setShowMore(true); }}>Show more</a>
                    }
                </Col>
                <Col data-unread-msg={!is_read}>
                    {getMessageDate(message_dt)}
                </Col>
            </Row>
            <Row type="flex" style={{ justifyContent: 'center' }}>
                <Col>
                    <Dropdown overlay={menu} trigger={['click']} align={{ offset: ['-92%', 0] }}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <MoreOutlined style={{ transform: 'rotate(90deg)' }}/>
                        </a>
                    </Dropdown>
                </Col>
                <Col>
                    <DeleteOutlined style={{ verticalAlign: 'bottom' }} type="delete" onClick={removeMessage}/>
                </Col>
            </Row>
        </div>
    );
}

class NotificationBellButtonGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: null,
            profile_id: 0,
            messages: [],
            countUnread: 0,
            pagination: {
                current: 1,
                pageSize: 10,
                total: 100,
                showSizeChanger: true,
                size: ""
            },
            readFilter: "All",
            searchText: "",
            notificationListVisible: false,
            availableEventCodes: [],
            isLoadingRemarkImage: true,
        }
    }

    componentDidMount() {
        let { token, profile_id, readFilter, searchText, pagination } = this.state;
        if (profile_id !== Auth.getProfile().profile_id)
        {
            this.setAvailableEventCodes();
            this.setState({ profile_id: Auth.getProfile().profile_id });
        }

        if (token !== Auth.getToken() )
        {
            this.refreshUserNotification(readFilter, searchText, pagination.pageSize, pagination.current);
            subscribeUserNotification(this.refreshUserNotificationBySignalR);

            this.setState({ token: Auth.getToken() });
        }
    }

    componentDidUpdate() {
        let { token, profile_id, readFilter, searchText, pagination } = this.state;
        if (profile_id !== Auth.getProfile().profile_id)
        {
            this.setAvailableEventCodes();
            this.setState({ profile_id: Auth.getProfile().profile_id });
        }

        if (token !== Auth.getToken())
        {
            stopSignalR().then( () => {
                this.refreshUserNotification(readFilter, searchText, pagination.pageSize, pagination.current);
                subscribeUserNotification(this.refreshUserNotificationBySignalR);
            });

            this.setState({ token: Auth.getToken() });
        }
    }

    componentWillUnmount() {
        stopSignalR();
    }

    updateReadStatus = async (newStatus, message_id) => {
        const { messages, countUnread } = this.state;
        let message = messages.find(msg => msg.message_id === message_id);
        if (message) 
        {
            upsertMessage({ ...message, is_read: newStatus !== false });
            message.is_read = newStatus !== false;
            this.setState({ messages, countUnread: (newStatus !== false ? (countUnread-1 >= 0 ? countUnread-1 : 0) : countUnread+1) });
        }
    }

    refreshUserNotification = async (readFilter, searchText, pageSize, current) => {
        const { pagination } = this.state;

        getMessage(pageSize, current, readFilter, searchText).then(async res => {
                
            let bookingDict = [];
            let consolDict = [];
            for(var i=0; i<res.messages.length; i++)
            {
                if (["notification.msgBookingNewOperationRemark", "notification.msgBookingNewPricingRemark"].includes(res.messages[i].desc_key))
                {
                    let src = res.messages[i].desc_param.remark;
                    while(src.indexOf("src=\"cid:") > -1)
                    {
                        src = src.substring(src.indexOf("src=\"cid:")+9);
                        bookingDict.push({ idx: i, file_name: src.substring(0, src.indexOf('\"')) });
                        src = src.substring(src.indexOf('\"'));
                    }
                } 
                else if (["notification.msgConsolidationNewOperationRemark", "notification.msgConsolidationNewPricingRemark"].includes(res.messages[i].desc_key))
                {
                    let src = res.messages[i].desc_param.remark;
                    while(src.indexOf("src=\"cid:") > -1)
                    {
                        src = src.substring(src.indexOf("src=\"cid:")+9);
                        consolDict.push({ idx: i, file_name: src.substring(0, src.indexOf('\"')) });
                        src = src.substring(src.indexOf('\"'));
                    }
                }
            }
            
            const requests = [];
            
            if (bookingDict.length > 0)
            {
                requests.push(loadBookingApprovalRemarkImage(res.messages, bookingDict));
            }
            if (consolDict.length > 0)
            {
                requests.push(loadConsolidationRemarkImage(res.messages, consolDict));
            }
            if (requests.length > 0)
            {
                this.setState({ isLoadingRemarkImage: true });
                await Promise.all(requests);
            }

            this.setState({ 
                pagination: { ...pagination, total: readFilter === 'All' ? res.total_count : res.unread_count , pageSize, current }, 
                messages: res.messages, 
                countUnread: res.unread_count,
                isLoadingRemarkImage: false
            });
        });
    }

    refreshUserNotificationBySignalR = msgData => {
        let { readFilter, searchText, pagination, countUnread, availableEventCodes } = this.state;
        let { pageSize, current } = pagination;
        let accessRight = false;

        if (Array.isArray(availableEventCodes))
            accessRight = availableEventCodes.some(c => c.module === msgData.module && c.sub_module === msgData.sub_module && c.key == msgData.event_code);

        accessRight && getMessage(pageSize, current, readFilter, "").then(async res => {
            let msg = res.messages.find(msg => msg.guid === msgData.guid);
            
            try { msg.desc_param = JSON.parse(msg.desc_param); } catch(e) { console.log(e); };
            
            let html_desc = "";
            if (["notification.msgBookingNewOperationRemark", "notification.msgBookingNewPricingRemark",
                "notification.msgConsolidationNewOperationRemark", "notification.msgConsolidationNewPricingRemark"
            ].includes(msg.desc_key))
            {
                html_desc = `<div>${getText(msg.desc_key)}</div>`;
                for (const [key, value] of Object.entries(msg.desc_param)) 
                {
                    html_desc = html_desc.replace(`{${key}}`, value);
                }

                let dict = [];
                let src = html_desc;
                while(src.indexOf("src=\"cid:") > -1)
                {
                    src = src.substring(src.indexOf("src=\"cid:")+9);
                    dict.push({ file_name: src.substring(0, src.indexOf('\"')) });
                    src = src.substring(src.indexOf('\"'));
                }
                if (dict.length > 0)
                {
                    if (["notification.msgBookingNewOperationRemark", "notification.msgBookingNewPricingRemark"].includes(msg.desc_key))
                        html_desc = await loadBookingApprovalRemarkImage2(html_desc, msg, dict);
                    else if (["notification.msgConsolidationNewOperationRemark", "notification.msgConsolidationNewPricingRemark"].includes(msg.desc_key))
                        html_desc = await loadConsolidationRemarkImage2(html_desc, msg, dict);
                }
            }
            
            Notification.info({
                className: "notificationInsideElement",
                key: msg.guid,
                message: "",
                description: (
                    <div 
                        className="notificationMessage"
                        style={{ wordBreak: "break-word", cursor: 'pointer', marginRight: '20px' }} 
                        onClick={() => {
                            history.push(`${process.env.PUBLIC_URL}${msg.href}`);
                            this.updateReadStatus(true, msg.message_id);
                            Notification.close(msg.guid);
                        }}
                    >
                        {
                            ["notification.msgBookingNewOperationRemark", "notification.msgBookingNewPricingRemark",
                                "notification.msgConsolidationNewOperationRemark", "notification.msgConsolidationNewPricingRemark"
                            ].includes(msg.desc_key) ?
                            <div className="braft-output-content" style={{overflowX: "auto"}} dangerouslySetInnerHTML={{__html: html_desc}}></div>
                            : multiLine(getText(msg.desc_key, msg.desc_param))
                        }
                        {
                            msg.desc_key === "notification.msgBookingUpdateSailingA2" && Array.isArray(msg.desc_param.updated_sailing_detail) && 
                            <>
                                <br/>
                                {
                                    msg.desc_param.updated_sailing_detail.map( d => 
                                        <>{multiLine(getText("notification.msgBookingUpdateSailingA2Detail", d))}</>
                                    )
                                }
                            </>
                        }
                        {
                            msg.desc_key === "notification.msgBookingUpdateRouting" && Array.isArray(msg.desc_param.updated_routing_detail) && 
                            <>
                                <br/>
                                {
                                    msg.desc_param.updated_routing_detail.map( d => 
                                        <>{multiLine(getText("notification.msgBookingUpdateRoutingDetail", d))}</>
                                    )
                                }
                            </>
                        }
                        {
                            msg.desc_key === "notification.msgConsolidationUpdateSailingA2" && Array.isArray(msg.desc_param.updated_sailing_detail) && 
                            <>
                                <br/>
                                {
                                    msg.desc_param.updated_sailing_detail.map( d => 
                                        <>{multiLine(getText("notification.msgConsolidationUpdateSailingA2Detail", d))}</>
                                    )
                                }
                            </>
                        }
                    </div>
                ),
                placement: 'bottomRight',
                duration: 0
            });
            
            if (searchText === "")
            {
                this.setState({ 
                    pagination: { ...pagination, total: readFilter === 'All' ? res.total_count : res.unread_count , pageSize, current }, 
                    messages: res.messages, 
                    countUnread: res.unread_count 
                });
                this.forceUpdate(); // force update to refresh total count of page
            }
            else
                this.setState({ countUnread: countUnread+1 });
        }).catch(e => {
            console.log(`Refresh user notification fail: `, e);
        });
    }

    setAvailableEventCodes = async () => {
        let result = await getUserProfileAvailableEventCodes();
        this.setState({ availableEventCodes: result });
    }

    render() {
        const { messages, countUnread, pagination, readFilter, searchText, notificationListVisible, isLoadingRemarkImage } = this.state;

        const setNotificationListVisible = val => this.setState({ notificationListVisible: val })

        return (
            <>
                {
                    <BellButton 
                        notificationListVisible={notificationListVisible} 
                        setNotificationListVisible={setNotificationListVisible} 
                        countUnread={countUnread}
                    />
                }
                {
                    <NotificationList 
                        messages={messages}
                        countUnread={countUnread}
                        pagination={pagination}
                        readFilter={readFilter}
                        searchText={searchText}
                        notificationListVisible={notificationListVisible}
                        loading={isLoadingRemarkImage}
                        refreshUserNotification={this.refreshUserNotification}
                        setNotificationListVisible={setNotificationListVisible}
                        setMessages={val => this.setState({ messages: val })}
                        setCountUnread={val => this.setState({ countUnread: val })}
                        setReadFilter={val => this.setState({ readFilter: val })}
                        setSearchText={val => this.setState({ searchText: val })}
                        setPagination={val => this.setState({ pagination: val })}
                        updateReadStatus={this.updateReadStatus}
                    />
                }
            </>
        );
    };
}

export default withAuth(NotificationBellButtonGroup);
import React, { PureComponent } from 'react';
import { TimePicker } from 'antd';
import moment from 'moment';

class TimePickerWrapper extends PureComponent {
    render() {
        let { defaultValue, /*defaultPickerValue,*/ value, ...props } = this.props;
        const format = 'HH:mm'
        defaultValue = defaultValue ? moment(defaultValue, format) : null;
        //defaultPickerValue = defaultPickerValue ? moment(defaultPickerValue) : null;
        value = value ? moment(value, format) : null;
        return <TimePicker /*defaultPickerValue={defaultPickerValue}*/ defaultValue={defaultValue} value={value} {...props} />;
    }
}

export default TimePickerWrapper;

import React, { PureComponent } from 'react';
import CookieConsent from "react-cookie-consent";
import { Button, Modal } from './base';
import { getText } from '../i18n/i18next';

import './PrivacyPolicyAcceptance.scss';

class PrivacyPolicyAcceptance extends PureComponent {
    
    state = {
        modalPrivacyPolicyAcceptanceVisible: false,
    };

    setModalPrivacyPolicyVisible = modalPrivacyPolicyAcceptanceVisible => this.setState({ modalPrivacyPolicyAcceptanceVisible });
    
    render() {
        const { modalPrivacyPolicyAcceptanceVisible } = this.state;
        
        return (
            <>
                <CookieConsent
                    location="bottom"
                    cookieName="BMSPrivacyPolicyAcceptance"
                    expires={365}
                    containerClasses="privacy-policy-acceptance-container"
                    contentClasses="privacy-policy-acceptance-content"
                    buttonClasses="privacy-policy-acceptance-button"
                    buttonText="OK"
                >
                    Our website uses cookies. By continuing to use the website, you agree to the use of cookies. You can find further information on this topic in our data <a className="policy" onClick={() => this.setModalPrivacyPolicyVisible(true)}>privacy policy</a>.
                </CookieConsent>

                <Modal
                    title={
                        <div className="privacy-policy-acceptance-modal-header">
                            <h1>{getText('privacyPolicy.header')}</h1>
                        </div>
                    }
                    width={1000}
                    visible={modalPrivacyPolicyAcceptanceVisible}
                    onCancel={() => this.setModalPrivacyPolicyVisible(false)}
                    footer={null}
                >
                    <div className="privacy-policy-acceptance-modal-content">
                        <p>{getText('privacyPolicy.privacyPolicy1')}</p>
                        <p>{getText('privacyPolicy.privacyPolicy2')}</p>
                        <p><span>{getText('privacyPolicy.personalData')}</span>: {getText('privacyPolicy.personalDataContent')}</p>
                        <p><span>{getText('privacyPolicy.useOfPersonalData')}</span>: {getText('privacyPolicy.useOfPersonalDataContent')}</p>
                        <p><span>{getText('privacyPolicy.deletionOfPersonalData')}</span>: {getText('privacyPolicy.deletionOfPersonalDataContent')}</p>
                        <p><span>{getText('privacyPolicy.contract')}</span>: {getText('privacyPolicy.contractContent')}</p>
                        <p><span>{getText('privacyPolicy.passongOfPersonalData')}</span>: {getText('privacyPolicy.passongOfPersonalDataContent')}</p>
                        <p><span>{getText('privacyPolicy.cookies')}</span>: {getText('privacyPolicy.cookiesContent')}</p>
                        <p><span>{getText('privacyPolicy.webAnalysisTools')}</span>: {getText('privacyPolicy.webAnalysisToolsContent')}</p>
                    </div>
                </Modal>
            </>
        );
    }
};

export default PrivacyPolicyAcceptance;

import React, { Component } from 'react';

import { Button, Card, Form4, Input, Message } from '../components/base/index';
import { getText } from '../i18n/i18next';
import withTracker from '../components/withTracker';
import Ajax from '../components/Ajax';
import { SetTitle } from '../components/Common';

import { ReactComponent as CompanyLogo } from '../img/mr_spedag_logo.svg';
import userIcon from '../img/signup_title.png';

import './ForgotPassword.scss';

const FormItem4 = Form4.Item;

class ForgotPasswordForm extends Component {
    state = {
        userName: ''
    }

    componentDidMount() {
        SetTitle('Forgot Password');
    }

    onFinish = (values) => {
        const { userName } = values;

        Message.loading(getText('msgSubmitting'), 0);
        Ajax.post(`${process.env.PUBLIC_URL}/api/v1/Password/forgotpassword`, { userName })
            .then(res => {
                Message.destroy();

                if (res.status === 200 && res.data && res.data.success) {
                    const { history } = this.props;
                    Message.success(getText("msgSubmitForgotPwSuccessfully"), 5, () => { history.push('/'); });
                }
                else {
                    Message.error(getText("msgSubmitForgotPwFail"));
                }
            })
            .catch(err => {
                Message.destroy();
                if (err.response && err.response.data && err.response.data.Message) {
                    Message.error(err.response.data.Message, 30);
                }
                else {
                    Message.error(getText("msgSubmitForgotPwFail"));
                }
            });
    }

    render() {
        
        return (
            <div className="login-page">
                <Card bordered={false}>
                    <div className="forgotPasswordFormWrapper">
                        <Form4
                            onFinish={this.onFinish}
                            className="login-form"
                        >
                            <FormItem4
                                name='userName'
                                rules={[{
                                    required: true,
                                    message: 'Please input your username!'
                                }]}
                            >
                                <Input prefix={<img src={userIcon} alt="Username" className="loginIcon" />} placeholder="Username" />
                            </FormItem4>

                            <FormItem4>
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    {getText('lbForgotPW')}
                                </Button>
                            </FormItem4>
                        </Form4>
                    </div>
                    <div className="card-login-caption">
                        <CompanyLogo className="companyLogo" />
                    </div>
                </Card>
            </div>
        );
    }
}

export default withTracker(ForgotPasswordForm);
import { HubConnectionBuilder, HttpTransportType, LogLevel } from '@microsoft/signalr';

const baseUrl = process.env.REACT_APP_SIGNALR_WEB_API_URL;

export default class SignalR {
    // Initializing important variables
    constructor(hubName) {
        this.hubName = hubName;
        this.initConnection();
    }

    initConnection = () => {
        if (this.connection == null)
            this.connection = new HubConnectionBuilder()
                .configureLogging(LogLevel.None)
                .withUrl(`${baseUrl}/${this.hubName}`, {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets,
                    accessTokenFactory: () => localStorage.getItem('id_token')
                })
                .withAutomaticReconnect()
                .build();
    }

    on = async (funcName, callback) => {
        try {
            if (this.connection == null)
                this.initConnection();

            if (this.connection.state === 'Disconnected')
                await this.connection.start().catch(e => console.log(`${this.hubName} Connection failed: `, e));
            await this.connection.on(funcName, msg => callback && callback(msg));
        }
        catch(e) {
            console.log(`${funcName} subscribe failed: `, e);
        }
    }

    off = async () => {
        try {
            if (this.connection)
            {
                if (this.connection.state !== 'Disconnected')
                    this.connection.stop();
                this.connection = null;
            }
        }
        catch(e) {
            console.log(`User notification unsubscribe failed: `, e);
        }
    }

    send = async (funcName, dataObj) => {
        try {
            if (this.connection == null)
                this.initConnection();

            if (this.connection.state === 'Disconnected')
                await this.connection.start().catch(e => console.log(`${this.hubName} Connection failed: `, e));
            await this.connection.send(funcName, dataObj);
        }
        catch(e) {
            console.log('Send failed: ', e);
        }
    }
}
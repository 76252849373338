import React, { Component } from 'react';

import { Button, Card, Form4, Input, Message } from '../components/base/index';
//import AuthService from '../components/AuthService';
import { getText } from '../i18n/i18next';
import withTracker from '../components/withTracker';
import Ajax from '../components/Ajax';
import { SetTitle, checkStrongPassword, CheckIsNullOrUndefined } from '../components/Common';
import * as qs from 'query-string';

import { ReactComponent as CompanyLogo } from '../img/mr_spedag_logo.svg';
import passwordIcon from '../img/password_lock.png';

import './ForgotPassword.scss';

const FormItem4 = Form4.Item;
const dataUrl = `${process.env.PUBLIC_URL}/api/v1/password/resetpassword`;
const getPasswordConfig = `${process.env.PUBLIC_URL}/api/v1/Password/getPasswordConfig`;

class ResetPasswordForm extends Component {
    state = {
        loginID: '',
        password: '',
        confirmPassword: '',
        validateStatus: '',
        pwErrorMsg: '',
        token: '',
        strongPWRequired: false
    }

    componentDidMount() {
        SetTitle('Reset Password');

        const parsed = qs.parse(this.props.location.search);

        let url = new URL(dataUrl, window.location.href);
        url.searchParams.append('loginid', parsed.loginID);
        url.searchParams.append('token', parsed.token);

        this.getPasswordConfig();

        Ajax.get(url)
            .then(res => {
                if (res.status === 200 && res.data && res.data.success) {
                    this.setState({
                        loginID: parsed.loginID,
                        password: '',
                        confirmPassword: '',
                        validateStatus: '',
                        pwErrorMsg: '',
                        token: parsed.token
                    });
                }
                else {
                    this.setState({
                        loginID: '',
                        password: '',
                        confirmPassword: '',
                        validateStatus: '',
                        pwErrorMsg: '',
                        token: ''
                    });
                }
            })
            .catch(error => {
                this.setState({
                    loginID: '',
                    password: '',
                    confirmPassword: '',
                    validateStatus: '',
                    pwErrorMsg: '',
                    token: ''
                });
            });
    }

    getPasswordConfig = async () => {
        let url = new URL(getPasswordConfig, window.location.href);

        let result = await Ajax.get(url);

        this.setState({ strongPWRequired: result && result.data && result.data.success ? result.data.useStrongPassword : false });
    }

    passwordOnChange = (type, value) => {
        let obj = JSON.parse(JSON.stringify(this.state));

        obj[type] = value;
        this.setState(obj, this.comparePasswords);
    }

    comparePasswords = () => {
        const { password, confirmPassword, strongPWRequired } = this.state;

        if (strongPWRequired && !CheckIsNullOrUndefined(password) && password !== '' && !checkStrongPassword(password)) {
            this.setState({ validateStatus: 'error', pwErrorMsg: getText('msgStrongPWRequired') });
        }
        else if (password !== confirmPassword) {
            this.setState({ validateStatus: 'error', pwErrorMsg: getText('msgPwNotMatch') });
        }
        else {
            this.setState({ validateStatus: '', pwErrorMsg: '' });
        }
    }

    onFinish = (values) => {
        const { loginID, password, confirmPassword, token, strongPWRequired } = this.state;

        if (password !== confirmPassword || (strongPWRequired && !CheckIsNullOrUndefined(password) && password !== '' && !checkStrongPassword(password))) return;

        Message.loading(getText('msgSubmitting'), 0);
        Ajax.post(`${process.env.PUBLIC_URL}/api/v1/Password/resetpassword`, { loginID, password, token })
            .then(res => {
                Message.destroy();

                if (res.status === 200 && res.data && res.data.success) {
                    const { history } = this.props;
                    Message.success(getText("msgResetPwSuccessfully"), 5, () => { history.push('/'); });
                }
                else {
                    Message.error(getText("msgResetPwFail"));
                }
            })
            .catch(err => {
                Message.destroy();
                if (err.response && err.response.data && err.response.data.Message) {
                    Message.error(err.response.data.Message, 30);
                }
                else if (err.response && err.response.data && err.response.data.msgKey) {
                    Message.error(getText(err.response.data.msgKey));
                }
                else if (err.response && err.response.status) {
                    if (err.response.status === 404) Message.error('404 Not Found');
                    else if (err.response.status === 500) Message.error('500 Internal Server Error');
                    else Message.error(`${err.response.status} Error`);
                }
                else {
                    Message.error(getText("msgResetPwFail"));
                }
            });
    }

    render() {
        const { token, validateStatus, pwErrorMsg } = this.state;

        return (
            <div className="login-page">
                <Card bordered={false}>
                    <div className="forgotPasswordFormWrapper">
                        {token !== '' ?
                            <Form4 onFinish={this.onFinish} className="login-form">
                                <FormItem4
                                    name='password'
                                    rules={[{
                                        required: true,
                                        message: getText('msgPasswordRequired')
                                    }]}
                                >
                                        <Input prefix={<img src={passwordIcon} alt="Password" className="loginIcon" />} type="password" placeholder="Password" onChange={(e) => { this.passwordOnChange('password', e.target.value); }} />
                                </FormItem4>

                                <FormItem4
                                    name='confirmPassword'
                                    rules={[{
                                        required: true,
                                        message: getText('msgPasswordRequired')
                                    }]}
                                    validateStatus={validateStatus}
                                    help={pwErrorMsg}
                                >
                                        <Input prefix={<img src={passwordIcon} alt="Password" className="loginIcon" />} type="password" placeholder="Confirm Password" onChange={(e) => { this.passwordOnChange('confirmPassword', e.target.value); }} />
                                </FormItem4>

                                <FormItem4>
                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                        {getText('lbResetPW')}
                                    </Button>
                                </FormItem4>
                            </Form4>
                            :
                            <div style={{color: "white", paddingTop: "40%"}}>Invalid Token. Reset Password Fail.</div>
                        }
                        
                    </div>
                    <div className="card-login-caption">
                        {/*
                        <h1>{getText('lbMR')}</h1>
                        <h2>M+R Core System</h2>
                        <img src={companyLogo} alt="Logo" className="companyLogo" />
                         */}
                        <CompanyLogo className="companyLogo" />
                    </div>
                </Card>
            </div>
        );
    }
}

export default withTracker(ResetPasswordForm);
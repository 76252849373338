//import React from 'react';

const DynamicIcon = ({ type, ...rest }) => {
    const icons = require(`@ant-design/icons`);
    try {
        const Component = icons[type];
        if (Component) return <Component {...rest} />
        else return <div />
    }
    catch (error) {
        console.log('DynamicIcon error', error)
        return <div />
    }
}

export default DynamicIcon;
import React from 'react';
import { Button } from './base';
import { RollbackOutlined, EditOutlined } from '@ant-design/icons';

const EditButton = ({ editable, onEdit, onCancel, cancelText, editText, showAcceptRejectAll, acceptRejectAllText, onAcceptRejectAll, showLatestEBookingText, onShowLatestEbooking, disabled }) => (
    editable ?
        <><Button icon={<RollbackOutlined />} onClick={onCancel} disabled={disabled}>{cancelText}</Button> {showAcceptRejectAll ? <div><Button type="primary" icon={<EditOutlined />} onClick={onAcceptRejectAll} disabled={disabled}>{acceptRejectAllText}</Button><Button type="primary" style={{ marginLeft: '1em' }} onClick={onShowLatestEbooking} disabled={disabled}>{showLatestEBookingText}</Button></div> : ''}</>
        :
        <Button type="primary" icon={<EditOutlined />} onClick={onEdit} disabled={disabled}>{editText}</Button>
);

EditButton.defaultProps = {
    editable: false,
    cancelText: 'Cancel',
    editText: 'Edit',
    disabled: false
};

export default EditButton;

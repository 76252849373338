import en from './en';
import tc from './tc';
import i18next from 'i18next';
import React, { Fragment } from 'react';
import * as Common from '../components/Common';
const DefaultLang = "en";

i18next.init({
    debug: true,
    lng: DefaultLang,
    resources: {
        en: {
            translation: en
        },
        tc: {
            translation: tc
        }
    }
    
});

const lang_en = i18next.getFixedT(DefaultLang);

var changeLanguage = (l) => {
    var lang = DefaultLang;

    if (l === 'zh-HK') lang = 'tc';
    else if (l === 'zh-CN') lang = 'sc';

    i18next.language !== lang && i18next.changeLanguage(lang);
};

var getText = (key, options) => {
    return i18next.exists(key) ? i18next.t(key, options) : lang_en(key, options);
};

var multiLine = (text) => {
    return typeof text === 'string' ? text.replace('&amp;', '&').replace('&#x2F;', '/').replace('&#39;', "'").split('\n').map(item => <Fragment key={Common.GUID()}>{item.replace('&amp;', '&').replace('&#x2F;', '/').replace('&#39;', "'")}<br /></Fragment>) : '';
}

var currLang = i18next.language;

var exists = (key) => i18next.exists(key) ? true : false;

export { changeLanguage, getText, DefaultLang, multiLine, currLang, exists };
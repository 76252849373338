import React, { Component } from 'react';
import AuthService from './AuthService';
import TagManager from 'react-gtm-module'

export default function withTracker(WrappedComponent, options = {}) {
    const trackPage = (page) => {
        if (process.env.REACT_APP_GTM_CONTAINER_ID) {
            let user_id = 'anonymous';

            const userProfile = Auth.getProfile();
            const { sub } = userProfile;
            
            if (Auth.loggedIn()) {
                user_id = sub.toString();
            }

            TagManager.dataLayer({
                dataLayer: {
                    event: 'user_id_event',
                    user_id_event: user_id,
                    user_id,
                    userProject: 'BMS',
                    page,
                    ...options
                }
            });
        }
    };

    const Auth = new AuthService();

    class HOC extends Component {
        componentDidMount() {
            if (this.props.location) {
                trackPage(this.props.location.pathname);
            }
            //console.log('trackPage', this.props, this.props.location)
        }

        //UNSAFE_componentWillReceiveProps(nextProps) {
        //    const currentPage = this.props.location.pathname;
        //    const nextPage = nextProps.location.pathname;

        //    if (currentPage !== nextPage) {
        //        //console.log('componentWillReceiveProps', currentPage, nextPage)
        //        trackPage(nextPage);
        //    }
        //}

        //replace UNSAFE_componentWillReceiveProps
        componentDidUpdate(prevProps) {
            const currentPage = this.props.location.pathname;
            const prevPage = prevProps.location.pathname;

            if (currentPage !== prevPage) {
                //console.log('componentDidUpdate', currentPage, prevPage)
                trackPage(currentPage);
            }
        }
        //end replace UNSAFE_componentWillReceiveProps

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

    return HOC;
}
import 'babel-polyfill';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Input, Menu } from './base';
import DynamicIcon from './DynamicIcon';
import { LogoutOutlined, TeamOutlined } from '@ant-design/icons';

import Ajax from './Ajax';
import AuthService from './AuthService';
import { changeLanguage, getText, DefaultLang } from '../i18n/i18next';

import './SystemMenu.scss';
import i18next from 'i18next';

const SubMenu = Menu.SubMenu;
const Search = Input.Search;
const userMenuApiUrl = `${process.env.PUBLIC_URL}/api/v1/Users/UserModuleList`;
const loginUrl = `${process.env.PUBLIC_URL}/Login`;
const logoutUrl = `${process.env.PUBLIC_URL}/Logout`;
const changeUserUrl = `${process.env.PUBLIC_URL}/ChangeUser`;

let dataList = [];

const generateList = (data) => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const key = node.key;
        const title = node.title;
        dataList.push({ key, title });
        if (node.children) {
            generateList(node.children, node.key);
        }
    }
};

const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some(item => item.key === key)) {
                parentKey = node.key;
            } else if (getParentKey(key, node.children)) {
                parentKey = getParentKey(key, node.children);
            }
        }
    }
    return parentKey;
};

const getSelectedItemExpandKey = (location, menu) => {
    let defaultSelectedKeys = [];
    let expandedKeys = [];
    if (menu) {
        for (let m of menu) {
            if (m.children) {
                for (let c of m.children) {
                    if (location && c.url === `${location.pathname}${location.search}`) {
                        expandedKeys.push(m.key);
                        defaultSelectedKeys.push(c.key);
                        break;
                    }
                }
            }
            else if (location && m.url === `${location.pathname}${location.search}`) {
                defaultSelectedKeys.push(m.key);
            }
            if (defaultSelectedKeys.length > 0) break;
        }
    }
    return { defaultSelectedKeys, expandedKeys };
};

const defaultMenuDate = [
    {
        icon: 'HomeOutlined',
        key: 'home',
        title: 'Home',
        url: '/'
    },
    /*
    {
        icon: 'experiment',
        key: 'compareso',
        title: 'compare so',
        url: '/BMS/compareso'
    }
    */
];

export default class SystemMenu extends React.Component {
    constructor(props) {
        super(props);

        const { location } = this.props;

        let keyData = getSelectedItemExpandKey(location, defaultMenuDate);

        this.state = {
            expandedKeys: keyData.expandedKeys,
            defaultSelectedKeys: keyData.defaultSelectedKeys,
            defaultOpenKeys: keyData.expandedKeys,
            searchValue: '',
            selectedKeys: keyData.defaultSelectedKeys,
            menuData: defaultMenuDate
        };

        this.Auth = new AuthService();
        changeLanguage(localStorage.getItem('default_lang') || DefaultLang);
    }

    componentDidMount() {
        this.mounted = true;
        this.loadUserMenu();
    }

    componentDidUpdate(prevProps) {
        let prevUrl = `${prevProps.location.pathname}${prevProps.location.search}`;
        let currUrl = `${this.props.location.pathname}${this.props.location.search}`;
        
        if (prevUrl !== currUrl) {
            let keyData = getSelectedItemExpandKey(this.props.location, this.state.menuData);
            let obj = { selectedKeys: keyData.defaultSelectedKeys };
            if (Array.isArray(keyData.expandedKeys) && keyData.expandedKeys.length > 0) {
                obj.expandedKeys = keyData.expandedKeys;
            }
            this.cancellable.setState && this.cancellable.setState(obj);

            if (prevUrl === '/ChangeUser' || currUrl === '/ChangeUser') {
                this.loadUserMenu();
            }
            //this.cancellable.setState && this.cancellable.setState({ selectedKeys: keyData.defaultSelectedKeys, expandedKeys: keyData.expandedKeys });
        }
    }

    componentWillUnmount() {
        this.cancellable.setState = undefined;
    }

    loadUserMenu = () => {
        Ajax.get(userMenuApiUrl)
            .then(res => {
                let menuData = [...(JSON.parse(JSON.stringify(defaultMenuDate))), ...res.data];
                
                menuData.forEach((menu) => {
                    if (menu.section) {
                        //if (menu.section !== 'setting' && menu.section !== 'admin' && menu.section !== 'analytics' && menu.section !== 'notification_center' && menu.section !== 'sopms')
                        //    menu.children.unshift({
                        //        key: `${menu.key}_dashboard`,
                        //        section: menu.section,
                        //        title: 'Dashboard',
                        //        url: menu.url
                        //    });
                        menu.children.forEach((submenu) => {
                            submenu.url = `${process.env.PUBLIC_URL}${submenu.url}`;

                            if (i18next.exists(`menu.${submenu.title}`)) submenu.title = getText(`menu.${submenu.title}`);
                        });
                    }

                    menu.url = `${process.env.PUBLIC_URL}${menu.url}`;

                    if (i18next.exists(`menu.${menu.title}`)) menu.title = getText(`menu.${menu.title}`);
                });

                generateList(menuData);
                let keyData = getSelectedItemExpandKey(this.props.location, menuData);

                this.cancellable.setState && this.cancellable.setState({ menuData, selectedKeys: keyData.defaultSelectedKeys, expandedKeys: keyData.expandedKeys });
            })
            .catch(error => {
                this.cancellable.setState && this.cancellable.setState({ error });
            });
    }

    cancellable = {
        setState: this.setState.bind(this)
    };

    handleLogout = (e) => {
        e.preventDefault();

        const { history } = this.props;
        this.Auth.logout();
        history.push(loginUrl);
    }

    handleChangeUser = (e) => {
        e.preventDefault();

        const { history } = this.props;
        //this.Auth.logout();
        history.push(changeUserUrl);
    }

    handleSearchMenu = (e) => {
        const { menuData } = this.state;
        const { location } = this.props;
        const value = e.target.value;
        const expandedKeys = value.length > 0 ?
            dataList.map((item) => {
                if (item.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                    return getParentKey(item.key, menuData);
                }
                return null;
            }).filter((item, i, self) => item && self.indexOf(item) === i)
            : getSelectedItemExpandKey(location, menuData).expandedKeys;
        this.setState({
            expandedKeys,
            searchValue: value
        });
    }

    onOpenChange = (openKeys) => {
        this.setState({
            expandedKeys: openKeys
        });
    }

    displayMenuItems = () => {
        const { searchValue, menuData } = this.state;
        
        return menuData.map((m) => {
            const index = m.title.toLowerCase().indexOf(searchValue.toLowerCase());
            const searchStr = m.title.substring(index, index + searchValue.length);
            const beforeStr = m.title.substr(0, index);
            const afterStr = m.title.substr(index + searchValue.length);
            const title = searchValue.length > 0 && index > -1 ? (
                <Fragment>
                    {beforeStr}
                    <span style={{ color: '#f50' }}>{searchStr}</span>
                    {afterStr}
                </Fragment>)
                : m.title;
            
            if (m.children && m.children.length > 0) {
                return (
                    <SubMenu
                        key={m.key}
                        title={<span><DynamicIcon type={m.icon || "MailOutlined"} /><span>{title}</span></span>}
                    >
                        {m.children.map((sm) => {
                            const index = sm.title.toLowerCase().indexOf(searchValue.toLowerCase());
                            const searchStr = sm.title.substring(index, index + searchValue.length);
                            const beforeStr = sm.title.substr(0, index);
                            const afterStr = sm.title.substr(index + searchValue.length);
                            const title = searchValue.length > 0 && index > -1 ? (
                                <Fragment>
                                    {beforeStr}
                                    <span style={{ color: '#f50' }}>{searchStr}</span>
                                    {afterStr}
                                </Fragment>)
                                : sm.title;
                            return (<Menu.Item key={sm.key}><Link style={{ flex: 'auto' }} to={sm.url}>{title}</Link></Menu.Item>);
                        })}
                    </SubMenu>
                );
            }
            return (
                <Menu.Item key={m.key}>
                    <Link to={m.url}>
                        <DynamicIcon type={m.icon || "MailOutlined"} />
                        <span>{title}</span>
                    </Link>
                </Menu.Item>
            );
        });
}

    render() {
        const { expandedKeys, defaultOpenKeys, defaultSelectedKeys, selectedKeys } = this.state;
        
        return (
            <div>
                <Search style={{ margin: 8, width: 'auto' }} placeholder={getText('lblSearch')} onChange={this.handleSearchMenu} />
                <Menu
                    defaultOpenKeys={defaultOpenKeys}
                    defaultSelectedKeys={defaultSelectedKeys}
                    openKeys={expandedKeys}
                    selectedKeys={selectedKeys}
                    mode="inline"
                    theme="light"
                    onOpenChange={this.onOpenChange}
                >
                    {this.displayMenuItems()}
                    {
                        this.Auth.getProfile() && this.Auth.getProfile().super_user_uid &&
                        <Menu.Item key="changeuser">
                            <a href={changeUserUrl} onClick={this.handleChangeUser}>
                                <TeamOutlined />
                                <span>{getText('menu.ChangeUser')}</span>
                            </a>
                        </Menu.Item>
                    }
                    <Menu.Item key="logout">
                        <a href={logoutUrl} onClick={this.handleLogout}>
                            <LogoutOutlined />
                            <span>{getText('menu.Sign Out')}</span>
                        </a>
                    </Menu.Item>
                </Menu>
                
            </div>
        );
    }
}

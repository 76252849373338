import decode from 'jwt-decode';
import Ajax from './Ajax';
import { isArray } from 'util';
import { Notification, Modal, Message } from './base';
import React from 'react'
import moment from "moment";
import { getText } from '../i18n/i18next';

const loginUrl = `${process.env.PUBLIC_URL}/api/v1/Auth/login`;
const changeUserUrl = `${process.env.PUBLIC_URL}/api/v1/Auth/ChangeUser`;
const renewtokenUrl = `${process.env.PUBLIC_URL}/api/v1/Auth/renewtoken`;
const getCargosphereAccessTokenUrl = `${process.env.PUBLIC_URL}/api/v1/Auth/GetCargosphereAccessToken`;
const upsertPreferenceURL = `${process.env.PUBLIC_URL}/api/v1/users/UpsertUserPreferences`;
const upsertPreferenceWithTypeURL = `${process.env.PUBLIC_URL}/api/v1/users/UpsertUserPreferencesWithType`;
const getPreferenceListURL = `${process.env.PUBLIC_URL}/api/v1/users/GetUserPreferences`;
const deletePreferenceURL = `${process.env.PUBLIC_URL}/api/v1/users/DeleteUserPreferences`;

let refreshSailingFunc, refreshSailingFuncParam, refreshHBLSailingFunc, refreshHBLSailingFuncParam, refreshCargosphereAccessTokenStateFunc;

/**
 * @component
 * for handle authorization
 */
export const processLogout = () => {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('id_token');
    localStorage.removeItem('default_lang');
    localStorage.removeItem('default_page_size');
    localStorage.removeItem('user_profiles');
    localStorage.removeItem('uac');
    localStorage.removeItem('userStatus');
    localStorage.removeItem('table_layout_settings');
    localStorage.removeItem('sysNoticeList');
    localStorage.removeItem('sysAlertList');
    localStorage.removeItem('refreshSailing');
    localStorage.removeItem('refreshHBLSailing');
    localStorage.removeItem('updateSailingScheduleID');
    localStorage.removeItem('updateBookingDate');
    localStorage.removeItem('oiAPIToken');
    localStorage.removeItem('profileList');
    localStorage.removeItem('force_change_password');
    localStorage.removeItem('compsFilterSetting'); 
    localStorage.removeItem('user_preferences');

    delete Ajax.defaults.headers.common["Authorization"];
}

export default class AuthService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || ''; // API server domain

        Ajax.defaults.baseURL = this.domain;
        if (this.loggedIn())
            Ajax.defaults.headers.common['Authorization'] = `Bearer ${this.getToken()}`;
    }

    setAllLocalStorage = (dat) => {
        this.setToken(dat.token); // Setting the token in localStorage
        this.setDefaultLang(dat.default_lang);
        this.setDefaultPageSize(dat.default_page_size);
        this.setTableLayoutSettings(dat.table_layout_settings || '{}');
        this.setUserProfiles(dat.profiles);
        this.setUac(dat.uac);
        this.setUserStatus(dat.userStatus);
        this.setSysNoticeList(dat.sysNoticeList);
        this.setSysAlertList(dat.sysAlertList);
        this.setUserPreferences(dat.user_preferences);
        this.setForceChangePassword(dat.force_change_password);
        //this.setOIAPIToken(dat.oiAPIToken);
    }

    login = (username, password, language) => {
        // Get a token from api server using the fetch api
        return Ajax.post(`${this.domain}${loginUrl}`, { username, password, language })
            .then(res => {
                this.setAllLocalStorage(res.data);

                this.prompNoticeMsg();

                return Promise.resolve(res);
            });
    }

    changeUser = (login_id) => {
        // Get a token from api server using the fetch api
        return Ajax.post(`${this.domain}${changeUserUrl}/${login_id}`)
            .then(res => {
                this.setAllLocalStorage(res.data);

                this.prompNoticeMsg();

                return Promise.resolve(res);
            });
    }

    renewIfNeeded = (callback) => {
        if (this.needRenewToken(this.getToken())) {
            if (this.getProfile().profile) {
                return Ajax.post(`${this.domain}${renewtokenUrl}`)
                    .then(res => {
                        this.setToken(res.data.token); // Setting the token in localStorage
                        this.setUserProfiles(res.data.profiles);
                        this.setUac(res.data.uac);
                        this.setUserStatus(res.data.userStatus);
                        this.setUserPreferences(res.data.user_preferences);
                        this.setSysAlertList(res.data.sysAlertList);

                        callback && callback();

                        return Promise.resolve(res);
                    });
            }            
        }
    }

    selectUserProfile = (profile) => {
        return Ajax.post(`${this.domain}${renewtokenUrl}/${profile}`)
            .then(res => {
                this.setToken(res.data.token); // Setting the token in localStorage
                this.setUserProfiles(res.data.profiles);
                this.setUac(res.data.uac);
                this.setUserStatus(res.data.userStatus);
                this.setUserPreferences(res.data.user_preferences);

                return Promise.resolve(res);
            });
    }

    needRenewPreferences = async (profile) => {
        try {
            //return Ajax.post(`${this.domain}${renewtokenUrl}/${profile}`)
            //    .then(res => {
            //        this.setUserPreferences(res.data.user_preferences);
            //    });
            let res = await Ajax.post(`${this.domain}${renewtokenUrl}/${profile}`)

            this.setUserPreferences(res.data.user_preferences);
        } catch (error) {

        }
    }

    loggedIn = () => {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken(); // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token); // handwaiving here
    }

    needRenewToken = (token) => {
        try {
            const decoded = decode(token);
            return ((decoded.exp - 10 * 60) < Date.now() / 1000); // Checking if token need renew (10 mins before)
        }
        catch (err) {
            return false;
        }
    }

    isTokenExpired = (token) => {
        try {
            const decoded = decode(token);
            return (decoded.exp < Date.now() / 1000); // Checking if token is expired. N
        }
        catch (err) {
            return false;
        }
    }

    getTokenExpireTime = () => {
        try {
            const token = this.getToken();
            const decoded = decode(token);
            return (decoded.exp * 1000);
        }
        catch (err) {
            return (Date.now())
        }
    }

    setToken = (idToken) => {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken);
        Ajax.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
    }

    getToken = () => {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token');
    }

    setCargosphereAccessToken = (idToken) => {
        localStorage.setItem('cargosphere_access_token', idToken);
    }

    getCargosphereAccessToken = () => {
        return localStorage.getItem('cargosphere_access_token');
    }

    setDefaultLang = (default_lang) => {
        localStorage.setItem('default_lang', default_lang);
    }

    getDefaultLang = () => {
        return localStorage.getItem('default_lang');
    }

    setDefaultPageSize = (default_page_size) => {
        localStorage.setItem('default_page_size', default_page_size);
    }

    setTableLayoutSettings = (table_layout_settings) => {
        localStorage.setItem('table_layout_settings', table_layout_settings);
    }

    setSysNoticeList = (sysNoticeList) => {
        localStorage.setItem('sysNoticeList', sysNoticeList ? JSON.stringify(sysNoticeList) : '[]');
    }

    setSysAlertList = (sysAlertList) => {
        localStorage.setItem('sysAlertList', sysAlertList ? JSON.stringify(sysAlertList) : '[]');
    }

    getSysAlertList = () => {
        return localStorage.getItem('sysAlertList');
    }

    getSysNoticeList = () => {
        return localStorage.getItem('sysNoticeList');
    }

    getDefaultPageSize = () => {
        return localStorage.getItem('default_page_size');
    }

    getTableLayoutSettings = () => {
        return localStorage.getItem('table_layout_settings');
    }

    logout = () => processLogout();

    getProfile = () => {
        // Using jwt-decode npm package to decode the token
        try {
            return decode(this.getToken());
        }
        catch (err) {
            return {};
        }
    }

    setUserProfiles = (profiles) => {
        localStorage.setItem('user_profiles', profiles);
    }

    getUserProfiles = () => {
        return localStorage.getItem('user_profiles') && localStorage.getItem('user_profiles') !== '' ? localStorage.getItem('user_profiles').split(',') : [];
    }

    getPartyAccess = (party_type_id = null) => {
        const uac = this.getUac();
        if (uac && uac.party_accesses)
            return uac.party_accesses.filter(p => p.all_party || !party_type_id || p.party_type_id === party_type_id);
        return null;
    }

    getUserLevelId = () => {
        const uac = this.getUac();
        return uac && uac.user_level_id ? uac.user_level_id : null;
    }

    setUac = (uac) => {
        localStorage.setItem('uac', uac ? JSON.stringify(uac) : '{}');
    }

    getUac = () => {
        return JSON.parse(localStorage.getItem('uac'));
    }

    setUserStatus = (userStatus) => {
        localStorage.setItem('userStatus', userStatus);
    }

    getUserStatus = () => {
        return localStorage.getItem('userStatus');
    }

    setUserPreferences = (user_preferences) => {
        localStorage.setItem('user_preferences', user_preferences ? JSON.stringify(user_preferences) : '[]');
    }

    getUserPreferences = () => {
        return JSON.parse(localStorage.getItem('user_preferences'));
    }

    setForceChangePassword = (force_change_password) => {
        localStorage.setItem('force_change_password', force_change_password);
    }

    getForceChangePassword = () => {
        return localStorage.getItem('force_change_password') == 'true';
    }

    getUserFunctionAccess = (menu_id = null, func_id = null) => {
        const uac = this.getUac();
        if (uac && uac.menu_func_accesses)
            return uac.menu_func_accesses.filter(mf => (!menu_id || mf.menu_id === menu_id) && (!func_id || mf.func_id === func_id));
        return null;
    }

    setProfileList = (profileList) => {
        localStorage.setItem('profileList', profileList ? JSON.stringify(profileList) : '[]');
    }

    getProfileList = () => {
        return JSON.parse(localStorage.getItem('profileList'));
    }

    isAccessable = (menu_id = null, func_id = null) => {
        const uac = this.getUac();
        if (uac && uac.menu_func_accesses) {
            if (Array.isArray(func_id)) {
                return uac.menu_func_accesses.filter(mf => (!menu_id || mf.menu_id === menu_id) && (func_id.filter(f => (mf.func_id === f)).length > 0)).length > 0;
            }
            return uac.menu_func_accesses.filter(mf => (!menu_id || mf.menu_id === menu_id) && (!func_id || mf.func_id === func_id)).length > 0;
        }
        return false;
    }

    refreshCargosphereAccessToken = (callback, connection_test) => {
        return Ajax.post(`${this.domain}${getCargosphereAccessTokenUrl}`, connection_test || {})
            .then(res => {
                if (!connection_test) {
                    this.setCargosphereAccessToken(res.data);
                }
                callback && callback(res.data);
                return Promise.resolve(res);
            });
    }

    setRefreshCargosphereAccessTokenState = (func) => {
        refreshCargosphereAccessTokenStateFunc = func;
    }

    getRefreshCargosphereAccessTokenState = () => refreshSailingFunc;

    refreshCargosphereAccessTokenState = (refreshCargosphereAccessTokenStateFuncParam) => {
        try {
            return refreshCargosphereAccessTokenStateFunc && refreshCargosphereAccessTokenStateFunc(refreshCargosphereAccessTokenStateFuncParam);
        }
        catch (err) {
            console.log('AuthService::refreshCargosphereAccessTokenState err', err)
        }
    }

    getPRSMappingTargetOffice = (office_code) => {
        
        switch (office_code) {
            case 'SCN':
                office_code = "HKG";
                break;
            case 'LCB':
                office_code = "BKK";
                break;
            case "KUL":
                office_code = "JHB";
                break;
            case "SUB":
            case "BDO":
            case "SRG":
            case "SOL":
            case "DPS":
            case "CGK":
            case "MES":
                office_code = "JKK";
                break;
            case "HA9":
                office_code = "SGN";
        }

        return office_code
    }

    prompNoticeMsg = () => {
        let msgList = this.getSysNoticeList();
        
        msgList = msgList && JSON.parse(msgList) ? JSON.parse(msgList) : [];
       
        if (msgList && isArray(msgList) && msgList.length > 0) {
            for (let i = 0; i < msgList.length; ++i) {
                let msg = msgList[i];

                let body = msg.notice_body.split('\n').map((currValue, index) => {
                    let now = moment();
                    let nowUTC = now.subtract(now.utcOffset(), 'minute');
                    let to = moment(msg.valid_to).subtract(480, 'minute');
                    let remain = to.diff(nowUTC, 'minutes')

                    currValue = currValue.replace(/{{REMAIN}}/g, remain)

                    return <div key={index}>{currValue}</div>
                });

                if (msg.notice_type === 'NOTICE') {
                    Notification.info({
                        message: msg.notice_title,
                        description: <div style={{ wordBreak: "break-word" }}>{body}</div>,
                        placement: 'bottomRight',
                        duration: 0
                    });
                }
            }
        }
    }

    prompAlertMsg = () => {
        let msgList = this.getSysAlertList();

        msgList = msgList && JSON.parse(msgList) ? JSON.parse(msgList) : [];

        if (msgList && isArray(msgList) && msgList.length > 0) {
            for (let i = 0; i < msgList.length; ++i) {
                let msg = msgList[i];

                let body = msg.notice_body.split('\n').map((currValue, index) => {
                    let now = moment();
                    let nowUTC = now.subtract(now.utcOffset(), 'minute');
                    let to = moment(msg.valid_to).subtract(480, 'minute');
                    let remain = to.diff(nowUTC, 'minutes')

                    currValue = currValue.replace(/{{REMAIN}}/g, remain)

                    return <div key={index}>{currValue}</div>
                });
                let now = moment();
                let nowUTC = now.subtract(now.utcOffset(), 'minute');
                let from = moment(msg.valid_from).subtract(480, 'minute');
                let to = moment(msg.valid_to).subtract(480, 'minute');
                
                if (msg.notice_type === 'ALERT' && nowUTC.isBetween(from, to)) {
                    Modal.warning({
                        title: msg.notice_title,
                        content: <div style={{ wordBreak: "break-word" }}>{body}</div>
                    });
                }
            }
        }
    }

    setRefreshSailing = (func, param) => {
        refreshSailingFunc = func;
        refreshSailingFuncParam = param;
    }

    getRefreshSailing = () => refreshSailingFunc;

    refreshSailing = (updateBookingDate) => {
        try {
            return refreshSailingFunc && refreshSailingFunc(refreshSailingFuncParam, updateBookingDate);
        }
        catch (err) {
            console.log('AuthService::refreshSailing err', err)
        }
    }

    setRefreshHBLSailing = (func, param) => {
        refreshHBLSailingFunc = func;
        refreshHBLSailingFuncParam = param;
    }

    getRefreshHBLSailing = () => refreshHBLSailingFunc;

    refreshHBLSailing = () => {
        try {
            console.log('refreshHBLSailing', refreshHBLSailingFunc, refreshHBLSailingFuncParam)
            return refreshHBLSailingFunc && refreshHBLSailingFunc(refreshHBLSailingFuncParam);
        }
        catch (err) {
            console.log('AuthService::refreshHBLSailing err', err)
        }
    }

    //setOIAPIToken = (token) => {
    //    localStorage.setItem('oiAPIToken', token);
    //}

    //getOIAPIToken = () => {
    //    return localStorage.getItem('oiAPIToken');
    //}

    UpsertUserPreferences = async (preference_name, perference, url, callback, preference_style = '', isFavourite = false) => {
        Message.loading(getText('msgSaving'), 0);

        const data = {
            preference: JSON.stringify(perference),
            url,
            preference_name,
            preference_style,
            isFavourite
        }

        try {
            await Ajax.post(`${upsertPreferenceURL}`, data);
            Message.destroy();
            Message.success(getText("msgSavedSuccess"), 0);
            this.needRenewPreferences();
            callback && callback();
        } catch (error) {
            Message.destroy();
            Message.error(`${error}`);
        }
    }

    getUserPreferenceList = async (dat) => {
        const data = {
            dat
        }
        let url = new URL(`${getPreferenceListURL}`, window.location.href);
        url.searchParams.append('url', dat)
        try {
            let res = await Ajax.get(`${getPreferenceListURL}?url=${dat}`);
            return res;
        } catch (error) {
            Message.destroy();
            Message.error(`${error}`);
        }
    }

    
    UpsertUserPreferencesWithType = async (preference_id, preference_type, preference_name, perference, url, callback, preference_style = '', isFavourite = false) => {
        Message.loading(getText('msgSaving'), 0);

        const data = {
            preference_id,
            preference_type,
            preference: JSON.stringify(perference),
            url,
            preference_name,
            preference_style,
            isFavourite
        }

        try {
            await Ajax.post(`${upsertPreferenceWithTypeURL}`, data);
            Message.destroy();
            Message.success(getText("msgSavedSuccess"), 5);
            await this.needRenewPreferences();
            callback && callback();
        } catch (error) {
            Message.destroy();
            Message.error(`${error}`);
        }
    }

    DeleteUserPreferencesWithType = async (preference_id, callback) => {
        Message.loading(getText('msgSaving'), 0);

        const data = {
            preference_id
        }

        try {
            await Ajax.post(`${deletePreferenceURL}`, data);
            Message.destroy();
            Message.success(getText("msgSavedSuccess"), 5);
            await this.needRenewPreferences();
            callback && callback();
        } catch (error) {
            Message.destroy();
            Message.error(`${error}`);
        }
    }

    getCompsFilterSetting = () => {
        return localStorage.getItem('compsFilterSetting');
    }

    setCompsFilterSetting = (setting) => {
        localStorage.setItem('compsFilterSetting', setting ? JSON.stringify(setting) : '{}');
    }
}

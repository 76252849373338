import React from 'react';
import { Slider } from 'antd';
import './SliderEnhance.scss';

class SliderEnhance extends React.PureComponent {

    render() {
        let { remainder, max, marks, disabled, ...other } = this.props;
        const originTrackFlex = (remainder || 0);
        const greenTrackFlex = (max || 0) - (remainder || 0);

        if (originTrackFlex > 0 && greenTrackFlex > 0)
            marks = { [remainder]: remainder, ...marks };
        
        return (
            <div className="CustomSlider">
                {
                    originTrackFlex >= 0 &&
                    <Slider max={remainder} marks={marks} disabled={disabled} {...other} style={{flex: originTrackFlex}}/>
                }
                {
                    greenTrackFlex >= 0 &&
                    <div className={(disabled ? 'disabledTrack' : 'greenTrack') + ' ant-slider-track'} style={{flex: greenTrackFlex}} />
                }
            </div>
        );
    }
}

export default SliderEnhance;
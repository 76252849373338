import React from 'react';
import { Link, matchPath } from 'react-router-dom';
import { Breadcrumb } from './base';

const getBreadcrumbItem = (routes, url) => {
    const route = routes.filter(r => r.path && matchPath(url, r)).shift();
    if (!route || !route.name) return null;
    return (
        <Breadcrumb.Item key={url}>
            <Link to={url}>
                {route.name}
            </Link>
        </Breadcrumb.Item>
    );
};

const generateBreadcrumbItems = (routes, location) => {
    const pathSnippets = location.pathname.split('/').filter(i => i);
    const rootBreadcrumbItem = getBreadcrumbItem(routes, '/');
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        return getBreadcrumbItem(routes, url);
    }).filter(i => i);
    return [rootBreadcrumbItem, ...extraBreadcrumbItems].filter(i => i);
};

const BreadcrumbBar = ({ routes, location }) => (
    <Breadcrumb className="breadcrumb" separator=">">
        {generateBreadcrumbItems(routes, location)}
    </Breadcrumb>
);

export default BreadcrumbBar;
